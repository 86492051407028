import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router';

import { TOAST_LOCAL_PATH } from 'src/public/js/siteUtilities';
import { useOptionalRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import { useRestaurantRoutes } from 'shared/components/common/restaurant_routes/RestaurantRoutesContext';

import { ProfileAddresses } from 'public/components/default_template/online_ordering/account/profile_addresses/ProfileAddresses';
import { ProfileInformation } from 'public/components/default_template/online_ordering/account/profile_information/ProfileInformation';
import { ProfileSettings } from 'public/components/default_template/online_ordering/account/profile_settings/profileSettings';
import { ProfileWallet } from 'public/components/default_template/online_ordering/account/profile_wallet/ProfileWallet';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';

interface AccountProps {}

const Account: FC<AccountProps> = () => {
  const { customer, loadingCustomer, refetchCustomer } = useCustomer();
  const history = useHistory();
  const { orderPath, homePath } = useRestaurantRoutes();
  const selectedLocation = useOptionalRestaurant()?.selectedLocation;

  // refetch the customer to get the latest data
  useEffect(() => {
    refetchCustomer();
  }, [refetchCustomer]);

  useEffect(() => {
    if(!loadingCustomer && !customer) {
      if(homePath === TOAST_LOCAL_PATH) {
        history.push(homePath);
      } else {
        orderPath && history.push(orderPath);
      }
    }
  }, [customer, history, loadingCustomer, orderPath, homePath]);

  return (
    <>
      <main className="account" aria-label="My Account Information" tabIndex={-1} id="main">
        <h1 className="accountHeading">My Account</h1>
        <div className="accountSections" data-testid="Account">
          <div className="informationSection"><ProfileInformation /></div>
          <div className="addressSection"><ProfileAddresses selectedLocationLat={selectedLocation?.lat} selelctedLocationLong={selectedLocation?.long} /></div>
          <div className="walletSection"><ProfileWallet /></div>
          <div className="settingsSection"><ProfileSettings /></div>
        </div>
      </main>
    </>
  );
};

export default Account;
