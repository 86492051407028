import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router';

import { ApolloProvider } from '@apollo/client';
import { useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';

import { createClient } from 'src/apollo/createClient';
import { PopupButtonActionType, PopupLayout, useSubmitFormPopupMutation } from 'src/apollo/sites';
import { reportError } from 'src/lib/js/clientError';

import Image from 'shared/components/common/Image';
import Button from 'shared/components/common/button';
import FormInput from 'shared/components/common/form_input';
import { Modal, ModalCloseButton, ModalContent, ModalOverlay, useModal } from 'shared/components/common/modal';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import PromoCode from 'public/components/default_template/popups/PromoCode';
import { LoyaltySignupPopup } from 'public/components/default_template/popups/loyalty_signup/LoyaltySignupPopup';

import { resources } from 'config';

import { getHasShown, setHasShown, toRichTextField } from './utils';

type formData = { [key: string]: string };

type Props = {
  isOrderPage?: boolean;
}

type WrappedProps = {
  route: string
} & Props

const WrappedPopups = ({ route, isOrderPage }: WrappedProps) => {
  const { isEditor, popupGuid, useEditableRef } = useEditor();
  const { restaurant } = useRestaurant();
  const { isOpen, onClose, onOpen } = useModal();
  const formMethods = useForm<formData>();
  const { handleSubmit } = formMethods;
  const [submitFormPopupMutation] = useSubmitFormPopupMutation();
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  // Find the most applicable popup for the current page
  // 1. Is enabled
  // 2. Can be shown on the current page
  // Unless we're in the editor and we're viewing a specific popup
  const popupIndex = useMemo(() => restaurant.content?.popups?.findIndex(popup => {
    if(isEditor && popupGuid) {
      return popup.guid === popupGuid;
    }

    // If popup.applicablePages is null, it should be shown on all pages, if it is the empty list it should not be shown on any pages.
    return popup.enabled &&
      (!popup.applicablePages || popup.applicablePages.includes(route) ||
      isOrderPage && popup.applicablePages.includes('/order'));
  }), [restaurant, route, isEditor, popupGuid, isOrderPage]);
  const popup = typeof popupIndex !== 'undefined' && popupIndex >= 0 ? restaurant.content?.popups?.[popupIndex] : undefined;

  const { editableRef } = useEditableRef<HTMLDivElement>({
    name: 'popup',
    path: `content.popups[${popupIndex}]`,
    actions: ['delete'],
    schema: { fields: [] }
  });

  const onSubmit = useCallback((data: formData) => {
    if(!popup) {
      // this should never happen
      return;
    }

    setSubmitting(true);
    try {
      submitFormPopupMutation({
        variables: {
          formId: popup.guid,
          data: data
        }
      });
      setSubmitted(true);
    } catch(e) {
      reportError('Failed to submit popup form', e);
    } finally {
      setSubmitting(false);
      setTimeout(() => {
        onClose();
      }, (popup?.form?.successTimeout || 0) * 1000);
    }
  }, [onClose, popup, submitFormPopupMutation]);

  useEffect(() => {
    if(!isEditor && typeof window !== 'undefined' && popup?.enabled && !getHasShown(popup.guid)) {
      setTimeout(() => {
        onOpen();
        setHasShown(popup.guid);
      }, (popup?.displayDelay || 0) * 1000);
    }
  }, [popup, onOpen, onClose, isEditor]);

  if(!popup) {
    return null;
  }

  const header = toRichTextField(popup.header);
  const description = toRichTextField(popup.description);
  const isShelf = popup.layout === PopupLayout.FormSlideIn;

  const contents =
    <>
      <div className="popupHeader">
        {popup.dismissible !== false && <ModalCloseButton />}
      </div>
      <div className="popup" style={{ backgroundColor: popup.backgroundColor || undefined }}>
        {popup.layout === PopupLayout.LoyaltySignup
          ?
          <LoyaltySignupPopup
            header={header.text} headerColor={header.color} subheaderColor={description.color}
            onClose={onClose} successTimeout={(popup?.form?.successTimeout || 3) * 1000} />
          :
          <>
            <div className="popupContent">
              {popup.promoCodeGuid ?
                <div style={{ color: description.color, fontFamily: description.fontFamily }}>
                  <PromoCode promoCodeGuid={popup.promoCodeGuid} fontFamily={description.fontFamily} />
                </div>
                : <h2 style={{ color: header.color, fontFamily: header.fontFamily }}>{header.text}</h2>}
              <div className="popupBody">
                {(popup.layout === PopupLayout.ImageAndText || popup.layout === PopupLayout.FormTextAndImage || popup.layout === PopupLayout.FormSlideIn) && popup.image ?
                  <Image className="image" src={popup.image} />
                  : null}
                <p style={{ color: description.color, fontFamily: description.fontFamily }}>{description.text}</p>
              </div>
              {submitted ? <h2>{popup.form?.successMessage}</h2> : null}
              {!submitted && (popup.layout === PopupLayout.FormTextAndImage || popup.layout === PopupLayout.FormTextOnly || popup.layout === PopupLayout.FormSlideIn) && popup.form ?
                <div className="popupForm">
                  <FormProvider {...formMethods}>
                    <form>
                      <FormInput id={popup.form.formLabel} label={popup.form.formLabel} required />
                      {popup.layout === PopupLayout.FormTextOnly && popup.form.secondaryFormLabel ?
                        <FormInput required id={popup.form.secondaryFormLabel} label={popup.form.secondaryFormLabel} />
                        : null}
                    </form>
                  </FormProvider>
                </div>
                : null}
            </div>
            <div className="popupActions">
              {popup.buttons?.map((btn, i) => {
                if(!btn) return null;
                return (
                  <Button
                    key={`modal-action-${i}`}
                    disabled={submitting || submitted}
                    onClick={() => {
                      switch(btn.action?.action) {
                        case PopupButtonActionType.Link:
                          if(btn.action?.metadata) {
                            window.open(btn.action?.metadata, '_blank', 'noopener noreferrer');
                          }
                          onClose();
                          break;
                        case PopupButtonActionType.Submit:
                          if(popup.form) {
                            handleSubmit(onSubmit)();
                          } else {
                            onClose();
                          }
                          break;
                        case PopupButtonActionType.Close:
                        default:
                          onClose();
                          break;
                      }
                    }}
                    variant={btn.buttonType}>
                    {btn.text}
                  </Button>
                );
              })}
              {!popup.buttons?.length && popup.button ?
                <Button disabled={submitting || submitted} onClick={() => {
                  if(popup.form) {
                    handleSubmit(onSubmit)();
                  } else {
                    onClose();
                  }
                }} variant={popup.button.buttonType}>
                  {popup.button?.text}
                </Button>
                : null}
            </div>
          </>}
      </div>
    </>;

  if(isEditor) {
    return (
      <div className="editorPopup" data-testid="popup-modal-editor">
        <div className="editorPopupOverlay" style={{ opacity: popup.overlayOpacityPercentage ? popup.overlayOpacityPercentage / 100 : undefined, backgroundColor: popup.overlayColor || undefined }} />
        <div className={classnames('editorPopupWrapper', popup.placement, { popupShelf: isShelf })}>
          <div className="editorPopupContents" style={{ backgroundColor: popup.backgroundColor || undefined }} ref={editableRef}>
            {contents}
          </div>
        </div>
      </div>
    );
  }


  return (
    <Modal isOpen={isOpen} onClose={onClose} testId="popup-modal" preventOverlayClose={popup.dismissible === false}>
      <ModalOverlay color={popup.overlayColor || undefined} opacity={popup.overlayOpacityPercentage || undefined} fadeIn={isShelf} fadeOut={isShelf} />
      <ModalContent style={{ backgroundColor: popup.backgroundColor || undefined }}
        wrapperClassName={classnames('popupWrapper', popup.placement, { popupShelf: isShelf })} contentClassName="content" slideIn={isShelf} slideOut={isShelf}>
        {contents}
      </ModalContent>
    </Modal>
  );
};

const EditorPopups = () => {
  const { url, popupMode } = useEditor();

  if(!popupMode) {
    return null;
  }

  return <WrappedPopups route={url} />;
};

const SitesPopups = (props: Props) => {
  const { pathname } = useLocation();

  return (
    <ApolloProvider client={createClient(resources.apiEndpoint, undefined, false, false, undefined, false, false, resources.clientQueryTimeoutMs)}>
      <WrappedPopups route={pathname} isOrderPage={props.isOrderPage} />
    </ApolloProvider>
  );
};

const Popups = (props: Props) => {
  const { isEditor } = useEditor();

  return isEditor ? <EditorPopups /> : <SitesPopups {...props} />;
};

export default Popups;
