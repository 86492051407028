import React, { useMemo } from 'react';

import { ChevronRightIcon, DeliveryIcon, LocationIcon } from '@toasttab/buffet-pui-icons';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';
import { useCart } from 'src/public/components/online_ordering/CartContext';
import { useDelivery } from 'src/public/components/online_ordering/DeliveryContext';
import { useFulfillment } from 'src/public/components/online_ordering/FulfillmentContext';
import { formatDeliveryAddress } from 'src/public/components/online_ordering/addressUtils';
import { formatFullAddress } from 'src/shared/components/common/form_input/LocationInput';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';
import { LegacyDiningBehaviorToggle } from 'public/components/default_template/online_ordering/dining_behavior_toggle/FulfillmentControl';
import { SimpleDiningOptionBehaviorMessage } from 'public/components/default_template/online_ordering/dining_option_message/DiningOptionBehaviorMessage';
import OrderOptions from 'public/components/default_template/online_ordering/order_options/OrderOptions';

const AddressOnlyFulfillmentHeader = () => {
  const { fulfillmentData } = useFulfillment();
  const { validDeliveryAddress } = useDelivery();
  const { cart } = useCart();
  const { selectedLocation, locations } = useRestaurant();

  const isDelivery = useMemo(
    () => fulfillmentData?.cartFulfillmentData.diningOptionBehavior === DiningOptionBehavior.Delivery,
    [fulfillmentData?.cartFulfillmentData.diningOptionBehavior]
  );

  return (
    <div data-testid="AddressOnlyFulfillmentHeader" className="diningOptionBehavior addressOnly">
      { isDelivery ? <DeliveryIcon className="labelIcon" /> : <LocationIcon className="labelIcon" /> }
      <span data-testid="formattedAddress" className="address">
        {isDelivery ?
          formatDeliveryAddress(cart ? cart.order?.deliveryInfo : validDeliveryAddress, true) :
          formatFullAddress(selectedLocation)}
      </span>
      <div>
        { isDelivery || (locations?.length || 0) > 1 ? <ChevronRightIcon className="labelIcon" /> : null }
      </div>
    </div>
  );
};

const MobileFulfillment = ({ expanded }: { expanded: boolean }) => {
  return (
    <AnimatedSection expanded={expanded}>
      <div data-testid="MobileFulfillment" className="mobileFulfillment hidden-md-up">
        <LegacyDiningBehaviorToggle saveChange withSpinner className="fullWidthFulfillment" iconSize="md" />
        <SimpleDiningOptionBehaviorMessage />
        <OrderOptions>
          <AddressOnlyFulfillmentHeader />
        </OrderOptions>
      </div>
    </AnimatedSection>
  );
};

export default MobileFulfillment;
