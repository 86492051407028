
import { Maybe, RankedPromoOfferDiscount } from 'src/apollo/onlineOrdering';
import { Menus } from 'src/public/components/online_ordering/useOOMenu';

export const getGroupNameFromGuid = (guid: string, menus: Menus) => {
  for(const menu of menus) {
    if(menu?.groups) {
      for(const group of menu.groups) {
        if(group?.guid === guid) {
          return group.name;
        }
      }
    }
  }
  return null;
};

export const getMenuNameFromGuid = (guid: string, menus: Menus) => {
  for(const menu of menus) {
    if(menu?.guid === guid) {
      return menu.name;
    }
  }
  return null;
};

export const getMenuItemFromGuid = (guid: string, menus: Menus) => {
  for(const menu of menus) {
    if(menu?.groups) {
      for(const group of menu.groups) {
        if(group?.items) {
          for(const item of group.items) {
            if(item?.guid === guid) {
              return item;
            }
          }
        }
      }
    }
  }
  return null;
};

export const scrollToMenu = (guid: string, isGroup: boolean) => {
  const element = document.getElementById(`menu${isGroup ? 'Group' : ''}-${guid}`);
  if(element) {
    element.focus();
    element.scrollIntoView();
  }
};

export const getOfferPrice = (offer: RankedPromoOfferDiscount | undefined, price: number) => {
  if(!offer) {
    return price;
  }
  let discountValue : Maybe<number> | undefined;
  if(offer.discount?.amountType === 'BOGO') {
    discountValue = offer.discount?.bogoAction?.discountValue;
  } else {
    discountValue = offer.discount?.discountValue;
  }
  if(offer.discount?.amountType === 'FIXED' || offer.discount?.bogoAction?.amountType === 'FIXED') {
    return price - (discountValue ?? 0);
  }
  // Percent based
  return price - (discountValue ? discountValue / 100 * price : 0);
};

export const getTopOffer = (rankedOffers: RankedPromoOfferDiscount[], currentAppliedOffer: string | undefined) => {
  if(currentAppliedOffer) {
    return rankedOffers.find(offer => offer.promoCode === currentAppliedOffer);
  }
  for(const offer of rankedOffers) {
    // If the top offer is a BOGO and its not >= 75% complete, skip it
    if(offer.discount?.amountType === 'BOGO' && offer.percentCompleteInt >= 75 || offer.discount?.amountType !== 'BOGO') {
      return offer;
    }
  }
  return null;
};

export enum OfferBannerState {
  ADD_ITEM = 'ADD_ITEM',
  PROGRESS = 'PROGRESS',
  ACHIEVED = 'ACHIEVED',
  APPLIED = 'APPLIED'
}
