import React, { useState } from 'react';

import { formatCurrency, Currency as NumberUtilsCurrency } from '@toasttab/buffet-pui-number-utilities';
import { differenceInDays } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Currency, Money, useGuestWalletQuery } from 'src/apollo/onlineOrdering';
import { useFormatMoney } from 'src/lib/js/hooks/useFormatPrice';
import Image from 'src/shared/components/common/Image';

import { CreditCardRow } from 'public/components/default_template/online_ordering/checkout/payment/SavedCreditCards';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';

export const ProfileWallet = (props: { nowDate?: Date }) => {
  return (
    <div className="profileWallet" data-testid="ProfileWallet" role="form" tabIndex={0}>
      <h2 className="profileWalletHeading">
        <strong>My Wallet</strong>
      </h2>
      <div className="profileWalletSections">
        <ToastCashBalance nowDate={props.nowDate} />
        <SavedCreditCards />
      </div>
    </div>
  );
};

const ToastCashBalance = (props: { nowDate?: Date }) => {
  const { ooToastCashSpend: ooToastCashSpendEnabled } = useFlags();
  const hasCustomer = Boolean(useCustomer().customer);
  const { data } = useGuestWalletQuery({ skip: !hasCustomer || !ooToastCashSpendEnabled, variables: { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone } });
  const formatMoney = useFormatMoney();

  const amountExpiringMessage = React.useMemo(() => {
    const expiringValue = data?.guest.wallet.guestCurrencyAccount.nearestFutureExpirableDayValue;
    if(!expiringValue) return null;
    return transformExpirationToGuestMessage(expiringValue.amount, new Date(expiringValue.expirationDate), props.nowDate ?? new Date());
  }, [data?.guest.wallet.guestCurrencyAccount.nearestFutureExpirableDayValue, props.nowDate]);

  if(!data) {
    return null;
  }

  return (
    <div>
      <h3 className="walletSubheading">Your Toast Cash*</h3>
      <br />
      <div className="toastCashCardContainer" tabIndex={0} aria-labelledby="#toast-cash-funds">
        <div
          className="toastCashCard">
          <div className="toastCashIconContainer">
            <Image alt="Toast Cash" src="icons/toast-logo-filled.svg" />
            <div id="toast-cash-funds">
              <div className="toastCashAmount">
                {formatMoney(data.guest.wallet.guestCurrencyAccount.availableBalance)}
              </div>
              <div className="toastCashAvailableFunds">{amountExpiringMessage ? amountExpiringMessage : 'Available funds'}</div>
            </div>
          </div>
        </div>
        <div className="toastCashDisclaimer">
          * Total value of any purchased gift cards and any promo codes you&rsquo;ve received.{' '}
          {/* TODO: Fill in href link */}
          <a href="https://pos.toasttab.com">See terms.</a>
        </div>
      </div>
    </div>
  );
};

const SavedCreditCards = () => {
  const { customer } = useCustomer();
  const [errorMsg, setErrorMsg] = useState('');
  return (
    <div role="list" aria-label="Payment Methods">
      <div className="walletSubheading">Payment Methods</div>
      <br />
      {customer?.creditCards?.length
        ? customer?.creditCards.map(card => <CreditCardRow key={card.guid} {...card} onError={setErrorMsg} hideInputs={true} /> )
        : <div className="empty">Check back here after placing an order for your saved payment methods!</div>}
      {errorMsg && <div className="submitError">{errorMsg}</div>}
    </div>
  );
};


/**
 *
 * @param moneyAmount
 * @param expirationDate
 * @returns A message about near expiring credits.
 * Returns null if no user-facing message should be supplied
 */
function transformExpirationToGuestMessage(moneyAmount: Money, expirationDate: Date, nowDate: Date) {
  if(moneyAmount.currency !== Currency.Usd) {
    return null;
  }

  if(Number(moneyAmount.amount) <= 0) {
    return null;
  }

  const difference = differenceInDays(expirationDate, nowDate);

  if(difference < 0) {
    // If I get a time in the past, that is a smell that my client should be refreshing.
    // If client gets stale, indicated with a time in the past, it should trigger a refetch. I'm just going to avoid that complexity for now.
    // For now, we'll just show no message if that case does occur, and fall back to a default treatment.
    return null;
  }

  const moneyFormatted = `${formatCurrency({ amount: moneyAmount.amount, currency: NumberUtilsCurrency.USD }, 'en-US', { trailingZeros: false })}`;

  const daysFormatted = (function(): string {
    if(difference == 0) {
      return 'today';
    } else if(difference === 1) {
      return `in ${difference} day`;
    } else {
      return `in ${difference} days`;
    }
  })();

  return `${moneyFormatted} expiring ${daysFormatted}`;
}
