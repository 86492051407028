import React, { useEffect, useMemo, useRef, useState } from 'react';

import { LockLockedIcon, CloseIcon } from '@toasttab/buffet-pui-icons';
import classNames from 'classnames';

import { RewardIcon, TagIcon } from 'src/public/components/default_template/offers/OffersIcons';
import Image from 'src/shared/components/common/Image';

import ContextualLoadingSpinner from 'shared/components/common/loading_spinner/LoadingSpinner';

export enum DiscountPillState {
  ACTIVE,
  LOCKED,
  INACTIVE,
}
export enum DiscountPillType {
  OFFER,
  REWARD,
  BUTTON
}
type DiscountPillProps = {
  text: string;
  onClick?: () => void;
  pillKey?: string;
  state?: DiscountPillState
  type?: DiscountPillType;
  loadingOverride?: boolean;
  testId?: string;
};
const DiscountPill = ({ text, onClick, pillKey, state = DiscountPillState.INACTIVE, type = DiscountPillType.OFFER, loadingOverride, testId }: DiscountPillProps) => {
  const pillTextRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);

  const icon = useMemo(() => {
    if(type === DiscountPillType.BUTTON) {
      return null;
    }
    switch(state) {
      case DiscountPillState.ACTIVE:
        return (
          <div className="checkContainer">
            <Image src="icons/check.svg" className="checkIcon" data-testid="checkmark" />
          </div>
        );
      case DiscountPillState.LOCKED:
        return <div className="tagIcon" data-testid="lock-icon"><LockLockedIcon /></div>;
      default:
        return (
          <div className="tagIcon" data-testid="tag-icon">
            {type === DiscountPillType.OFFER ? <TagIcon color="#646464" /> : <RewardIcon color="#646464" />}
          </div>
        );
    }
  }, [state, type]);

  useEffect(() => {
    // changes the font size of all the pills if one of them is truncated
    if(pillTextRef.current &&
      (pillTextRef.current.offsetWidth < pillTextRef.current.scrollWidth ||
      pillTextRef.current.offsetHeight < pillTextRef.current.scrollHeight)) {
      document.querySelectorAll('.discountText').forEach(div => {
        div.classList.add('smallerText');
      });
    }
  });

  const handleClick = async () => {
    setLoading(true);
    await onClick?.();
    setLoading(false);
  };

  return (
    <button className={classNames(
      'discountPill',
      {
        active: state === DiscountPillState.ACTIVE,
        locked: state === DiscountPillState.LOCKED,
        buttonPill: type === DiscountPillType.BUTTON
      }
    )} onClick={handleClick} key={pillKey} type="button" data-testid={testId} disabled={loadingOverride}>
      {loading ? <ContextualLoadingSpinner size="28px" strokeWidth="3px" /> : icon}
      <div className="discountText" ref={pillTextRef}>{text}</div>
      {state === DiscountPillState.ACTIVE &&
          <div className="activeActions">
            <div className="divider" />
            <CloseIcon size="xs" testId="close-icon" className="closeIcon" />
          </div>}
    </button>
  );
};

export default DiscountPill;
