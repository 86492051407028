import React, { useMemo } from 'react';
import { withRouter } from 'react-router';

import { useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';
import { Organization } from 'schema-dts';

import {
  BulkCalls,
  Cta,
  LogoSizeOption,
  PaddingEnum,
  PageConfig,
  Scalars,
  SectionFieldsFragment,
  SeoMeta,
  SiteContentDataFragment,
  ThemeTypeEnum,
  useSiteContentByDomainQuery,
  useSiteContentByMgmtGuidQuery,
  useSiteContentByShortUrlQuery
} from 'src/apollo/sites';
import { RequestContextProps } from 'src/lib/js/context';
import { getHost, getPath } from 'src/lib/js/utilities';
import SchemaThing from 'src/public/components/seo/SchemaThing';
import { getImageUrl } from 'src/shared/components/common/Image';
import { BulkMenuItemsContextProvider } from 'src/shared/components/common/menu_item/MenuItemsContext';

import AttributionContextProvider from 'shared/components/common/attribution_context/AttributionContext';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import NoMatch404 from 'shared/components/no_match_404/NoMatch404';
import UhOh, { getUhOhPropsForError } from 'shared/components/uh_oh/UhOh';
import useRestaurantSiteData from 'shared/js/hooks/useRestaurantSiteData';

import pageAnimator from 'public/components/default_template/animation/pageAnimator';
import PageBanner from 'public/components/default_template/banner/PageBanner';
import { LoyaltySignupSection } from 'public/components/default_template/better_together_section/LoyaltySignupSection';
import CalendarEvents from 'public/components/default_template/calendar_events/CalendarEvents';
import Cards from 'public/components/default_template/cards/Cards';
import { CTAData } from 'public/components/default_template/ctas';
import { DynamicSection } from 'public/components/default_template/dynamic_section/DynamicSection';
import Footer from 'public/components/default_template/footer';
import FullScreenSection from 'public/components/default_template/full_screen/FullScreenSection';
import Gallery from 'public/components/default_template/gallery/Gallery';
import Hero from 'public/components/default_template/hero/Hero';
import ImageWithTags from 'public/components/default_template/image_with_tags/ImageWithTags';
import PageMeta from 'public/components/default_template/meta/PageMeta';
import Nav from 'public/components/default_template/nav/Nav';
import PhotoSection from 'public/components/default_template/photo_section/PhotoSection';
import { ReservationWidget } from 'public/components/default_template/reservation/Reservation';
import Reviews from 'public/components/default_template/reviews/Reviews';
import { Map } from 'public/components/default_template/schedule_map';

import SectionWrapper from './SectionWrapper';
import { generateDescription, generateTitle } from './generateMetaFields';

type SiteContent = SiteContentDataFragment['content'] & { __typename?: 'SiteContent' };

export type EditPathRoot = '' | `${string}.` | `${string}[${number}]`;

type Props = RequestContextProps & {
  content?: SiteContent | null;
  config?: PageConfig | null;
  host?: string;
  editPathRoot?: EditPathRoot;
  pageSeoMeta?: SeoMeta;
  bulkCalls?: BulkCalls | null | undefined;
};

type SectionsProps = {
  sections: Array<SectionFieldsFragment>;
  contentTheme?: ThemeTypeEnum | null;
  bodyPrimaryCta?: CTAData | null;
  bodyNonPrimaryCtas?: Array<CTAData>;
  meta: Scalars['JSON']['output'];
  editPathRoot: EditPathRoot;
};

export const MainPageSections = ({ sections, contentTheme, bodyPrimaryCta, bodyNonPrimaryCtas, meta, editPathRoot }: SectionsProps) => {
  const { isEditor, moduleWrapper: ModuleWrapper, dynamicModuleWrapper: DynamicModuleWrapper } = useEditor();

  if(!sections.length && isEditor && ModuleWrapper && DynamicModuleWrapper) {
    // Placeholder for pages with no existing sections
    // @ts-ignore TODO: update type to allow children
    return <ModuleWrapper index={-1}><></></ModuleWrapper>;
  }

  return (
    <div role="main" id="main" tabIndex={0}>
      {sections.map((section, index) => {
        const sectionTheme = section.theme || contentTheme;
        switch(section.__typename) {
          case 'DynamicSection':
            return (
              <SectionWrapper key={index} index={index} attributionUtmTerm={`dynamic_section_${index}`} {...section}>
                <DynamicSection
                  blocks={section.blocks}
                  numRows={section.numRows}
                  mobileNumRows={section.mobileNumRows}
                  editPath={`${editPathRoot}content.sections[${index}]`}
                  backgroundColor={section.backgroundColor}
                  padding={section.padding || PaddingEnum.None} />
              </SectionWrapper>
            );
          case 'HeroSection':
            return (
              <SectionWrapper key={index} index={index} attributionUtmTerm="hero_wrapper" {...section}>
                <Hero
                  editPath={`${editPathRoot}content.sections[${index}]`}
                  link={section.link}
                  isFirstSection={index === 0}
                  theme={sectionTheme}
                  images={section.images || []}
                  imagesPath={`${editPathRoot}content.sections[${index}].images`}
                  imageObject={section.images?.[0]}
                  imageObjectPath={`${editPathRoot}content.sections[${index}].images[0]`}
                  imgPosition={section.imgPosition}
                  videoSrc={section.video}
                  videoDescription={section.ariaText}
                  videoConfig={section.videoConfig}
                  textInset={section.textInset}
                  textWithBackground={section.textWithBackground}
                  backgroundSize={section.backgroundSize}
                  textBackgroundSize={section.textBackgroundSize}
                  header={section.heroHeader}
                  title={section.heroTitle}
                  body={section.heroBody}
                  button={section.heroButton}
                  backgroundColor={section.backgroundColor}
                  padding={section.padding} />
              </SectionWrapper>
            );
          case 'PhotoSection':
            return (
              <SectionWrapper key={index} index={index} attributionUtmTerm={`photo_section_${index}`} className="paddedSection" {...section}>
                <PhotoSection
                  editPath={`${editPathRoot}content.sections[${index}]`}
                  mainImage={section.mainImage}
                  featureImage={section.featureImage}
                  mainImgSrc={section.mainImg}
                  featureImgSrc={section.featureImg}
                  orientation={section.orientation}
                  header={section.header}
                  bodyText={section.body}
                  primaryCta={bodyPrimaryCta}
                  nonPrimaryCtas={bodyNonPrimaryCtas}
                  withUnderline={section.withUnderline}
                  padding={section.padding} />
                <ImageWithTags
                  className="hidden-md-up"
                  src={section.mainImg}
                  srcPath={`${editPathRoot}content.sections[${index}].mainImg`}
                  secondSrc={section.featureImg}
                  secondSrcPath={`${editPathRoot}content.sections[${index}].featureImg`} />
              </SectionWrapper>
            );
          case 'TagSection':
            return (
              <SectionWrapper key={index} index={index} attributionUtmTerm="tag_section" {...section}>
                <ImageWithTags
                  editPath={`${editPathRoot}content.sections[${index}]`}
                  image={section.image}
                  imagePath={`${editPathRoot}content.sections[${index}].image`}
                  src={section.image?.src}
                  srcPath={`${editPathRoot}content.sections[${index}].image.src`}
                  tags={section.tags}
                  padding={section.padding} />
              </SectionWrapper>
            );
          case 'ReviewSection':
            return (
              <SectionWrapper key={index} index={index} attributionUtmTerm="tag_section" testId="reviews-wrapper" {...section}>
                <Reviews
                  editPath={`${editPathRoot}content.sections[${index}]`}
                  id={`reviews-${index}`}
                  theme={sectionTheme}
                  header={section.reviewHeader}
                  reviews={section.reviews}
                  fillColor={meta.primaryColor}
                  backgroundColor={section.backgroundColor}
                  padding={section.padding} />
              </SectionWrapper>
            );
          case 'GallerySection':
            return (
              <SectionWrapper key={index} index={index} attributionUtmTerm="gallery_section" {...section}>
                <Gallery
                  editPath={`${editPathRoot}content.sections[${index}]`}
                  id={`gallery_section-${index}`}
                  header={section.galleryHeader}
                  images={section.images}
                  theme={sectionTheme}
                  primaryColor={meta.primaryColor}
                  backgroundColor={section.backgroundColor}
                  padding={section.padding} />
              </SectionWrapper>
            );
          case 'CardSection':
            return (
              <SectionWrapper key={index} index={index} attributionUtmTerm="cards_section" {...section}>
                <Cards
                  editPath={`${editPathRoot}content.sections[${index}]`}
                  id={`cards-idx-${index}`}
                  header={section.cardsHeader}
                  wrapCards={section.wrapCards}
                  cards={section.cards}
                  theme={sectionTheme}
                  isFirstSection={index === 0}
                  primaryColor={meta.primaryColor}
                  backgroundColor={section.backgroundColor}
                  padding={section.padding} />
              </SectionWrapper>
            );
          case 'ReservationSection':
            return (
              <SectionWrapper key={index} index={index} attributionUtmTerm="reservation_section" {...section}>
                <ReservationWidget
                  editPath={`${editPathRoot}content.sections[${index}]`}
                  imageObject={section.image}
                  imageObjectPath={`${editPathRoot}content.sections[${index}].image`}
                  image={section.reservationImage}
                  backgroundColor={section.backgroundColor}
                  padding={section.padding} />
              </SectionWrapper>
            );
          case 'LoyaltySignupSection':
            return (
              <SectionWrapper key={index} index={index} attributionUtmTerm="loyalty_signup_section" {...section}>
                <LoyaltySignupSection section={section} editPath={`${editPathRoot}content.sections[${index}]`} />
              </SectionWrapper>
            );
          case 'FullScreenSection':
            return (
              <SectionWrapper key={index} index={index} attributionUtmTerm="fullscreen_section" {...section}>
                <FullScreenSection editPath={`${editPathRoot}content.sections[${index}]`} {...section} />
              </SectionWrapper>
            );
          case 'CalendarSection':
            return (
              <SectionWrapper key={index} index={index} attributionUtmTerm="calendar_section" {...section}>
                <CalendarEvents
                  editPath={`${editPathRoot}content.sections[${index}]`}
                  header={section.calendarHeader}
                  backgroundColor={section.backgroundColor}
                  separatorColor={section.separatorColor}
                  padding={section.padding} />
              </SectionWrapper>
            );
          default:
            return null;
        }
      })}
    </div>
  );
};

export const MainPage = ({ staticContext, content: propContent, config, host = getHost(staticContext), editPathRoot = '', pageSeoMeta, bulkCalls }: Partial<Props>) => {
  const { isEditor } = useEditor();
  const { restaurant: defaultRestaurant, selectedLocation, restaurant, ooPromoBanners } = useRestaurant();
  const {
    siteRestaurant: dataRestaurant,
    error,
    loading
  } = useRestaurantSiteData({
    staticContext,
    host,
    domainQueryHook: useSiteContentByDomainQuery,
    shortUrlQueryHook: useSiteContentByShortUrlQuery,
    mgmtGuidQueryHook: useSiteContentByMgmtGuidQuery
  });

  React.useEffect(() => {
    if(restaurant.meta?.animateSectionsOnScroll) {
      return pageAnimator(isEditor);
    }
    return () => {};
  }, [restaurant.meta?.animateSectionsOnScroll, isEditor]);

  const siteRestaurant = isEditor ? defaultRestaurant : dataRestaurant;

  const content = (propContent || siteRestaurant?.content) as SiteContent || {};
  let { primaryCta = null, nonPrimaryCtas = null, sections = null, banner = null } = content;
  const { locations, meta, theme } = restaurant;

  const onRootPage = useMemo(() => getPath(staticContext) === '/' || getPath(staticContext) === '', [staticContext]);
  if(!host) {
    return <NoMatch404 meta={meta} siteTheme={theme} />;
  }

  if(error) {
    return <UhOh meta={meta} siteTheme={theme} {...getUhOhPropsForError(error)} />;
  }

  if(loading) {
    return null;
  }

  if(!propContent && !siteRestaurant && !loading && !error || !locations || !content || !content.sections || !sections) {
    let errorMessage: string | undefined = undefined;
    if(!locations) {
      errorMessage = 'Please assign a location to this brand in order to use the page.';
    }
    return <NoMatch404 errorMessage={errorMessage} meta={meta} siteTheme={theme} />;
  }

  if(!selectedLocation) {
    return <NoMatch404 meta={meta} siteTheme={theme} />;
  }

  // If propContent is defined but has CTAs, use the CTAs from the
  // Rx config. Usually CTAs are the same on all pages, so this makes configuring
  // templated pages easier.
  if(!nonPrimaryCtas?.length && !primaryCta) {
    nonPrimaryCtas = siteRestaurant?.content?.nonPrimaryCtas as Cta[] || [];
    primaryCta = siteRestaurant?.content?.primaryCta as Cta || null;
  }

  const bodyPrimaryCta = !primaryCta?.nav ? primaryCta as CTAData : null;
  const bodyNonPrimaryCtas = nonPrimaryCtas?.filter((cta: CTAData) => !cta.nav);
  const title = generateTitle(pageSeoMeta || meta, restaurant);
  const description = generateDescription(pageSeoMeta || meta, restaurant);
  const backgroundColorStyle = config?.backgroundColor ? { backgroundColor: config?.backgroundColor } : {};

  return (
    <div className="defaultTemplate" style={backgroundColorStyle} data-testid="mainPageWrapper">
      <PageMeta title={title} description={description} />
      <AttributionContextProvider utm_term="nav">
        <Nav logoSrc={meta.icon} logoObject={meta.iconObject} primaryCta={primaryCta} nonPrimaryCtas={nonPrimaryCtas} shouldShowPreviewBanner={true}>
          <PageBanner sitesBanner={banner} ooPromoBanners={ooPromoBanners} className={classnames({ padAroundLargeLogo: content.navConfig?.logoSize === LogoSizeOption.OverflowCircle })} />
        </Nav>
      </AttributionContextProvider>
      <BulkMenuItemsContextProvider bulkMenuItemIds={bulkCalls?.menuItems || siteRestaurant.content.bulkCalls?.menuItems }>
        <MainPageSections sections={sections} contentTheme={content.theme} bodyPrimaryCta={bodyPrimaryCta} bodyNonPrimaryCtas={bodyNonPrimaryCtas} meta={meta} editPathRoot={editPathRoot} />
      </BulkMenuItemsContextProvider>
      <Map
        name={restaurant.name}
        address1={selectedLocation.address1}
        address2={selectedLocation.address2}
        city={selectedLocation.city}
        state={selectedLocation.state}
        zipcode={selectedLocation.zipcode}
        alwaysShowMap={false} />
      <Footer />
      {onRootPage && siteRestaurant &&
      <SchemaThing<Organization> json={routes => ({
        '@context': 'https://schema.org',
        '@type': 'Organization',
        '@id': routes.homeUrl,
        url: routes.homeUrl,
        name: siteRestaurant.name,
        brand: siteRestaurant.name,
        logo: siteRestaurant.meta?.icon ? getImageUrl(siteRestaurant.meta.icon) : undefined,
        subOrganization: locations.map(loc => ({
          '@type': 'Restaurant',
          '@id': routes.orderUrl(loc.externalId),
          sameAs: routes.orderUrl(loc.externalId),
          name: loc.name,
          servesCuisine: siteRestaurant.meta?.cuisine,
          paymentAccepted: siteRestaurant.meta?.acceptedPaymentTypes,
          priceRange: siteRestaurant.meta?.priceRange,
          telephone: loc.phoneNumber,
          address: {
            '@type': 'PostalAddress',
            addressCountry: loc.country,
            postalCode: loc.zipcode,
            addressRegion: loc.state,
            addressLocality: loc.city,
            streetAddress: loc.address2 ? `${loc.address1}, ${loc.address2}` : loc.address1
          },
          image: siteRestaurant.meta?.icon ? getImageUrl(siteRestaurant.meta.icon) : undefined
        }))
      })} />}
    </div>
  );
};

export default withRouter<Props, React.ComponentType<Props>>(MainPage);
