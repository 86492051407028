import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import classnames from 'classnames';

import { RxSearchQueryResult } from 'src/apollo/onlineOrdering';
import useTracker from 'src/lib/js/hooks/useTracker';

import Image from 'shared/components/common/Image';

import { resources } from 'config';

import RXConfig from './RXConfig';

export const formatRXName = (name: string): string => {
  return name.replace(/\\r\\n/g, ' - ');
};

const genericRXImage = {
  src: 'toastLocal/common/generic-restaurant-1102.png',
  srcSet: [
    'toastLocal/common/generic-restaurant-276.png 276w',
    'toastLocal/common/generic-restaurant-551.png 551w',
    'toastLocal/common/generic-restaurant-1102.png 1102w'
  ],
  alt: 'Restaurant banner'
};

const ConditionalWrapper = ({ condition, wrapper, children }: { condition: boolean; wrapper: (n: React.ReactNode) => JSX.Element; children: JSX.Element}): JSX.Element =>
  condition ? wrapper(children) : children;

export const hasOORXProfile = (rx: Restaurant) => rx.hasOnlineOrderingModule && rx.shortUrl;

export type Restaurant = (NonNullable<RxSearchQueryResult['data']>['findConsumerAppRestaurants'] & { __typename: 'RestaurantSearchResults'})['results'][0];

type Props = {
  restaurant: Restaurant;
  utmContent?: string;
  className?: string;
}

const RXCard = ({ restaurant, className }: Props) => {
  const tracker = useTracker();

  const imageData = useMemo(() => {
    if(restaurant.consumerAppListImage) {
      return { src: restaurant.consumerAppListImage, cdnHost: resources.tlAssetUrl };
    }
    return { ...genericRXImage };
  }, [restaurant.consumerAppListImage]);

  const useOrderPage = useMemo(() => hasOORXProfile(restaurant), [restaurant]);

  const ooURLTracking = useMemo(
    () => useOrderPage
      ? `/local/order/${restaurant.shortUrl}/r-${restaurant.guid}`
      : '',
    [useOrderPage, restaurant]
  );

  // We want to preserver the referrer when linking to online ordering pages for tracking purposes
  /*eslint react/jsx-no-target-blank: "off"*/
  return (
    <div className={classnames('rx-card-container', !ooURLTracking && 'disabled', className)}>
      <ConditionalWrapper condition={!!ooURLTracking} wrapper={children =>
        <a href={ooURLTracking} target={useOrderPage ? '' : '_blank'} className="oo-link" onClick={() => tracker.page(ooURLTracking)}>
          {children}
        </a> } >
        <div className="rx-card-wrapper">
          <div className="rx-card">
            <div className="rx-image-wrapper">
              {imageData && <Image {...imageData} className="rx-image" />}
            </div>

            <div className="rx-details">
              <div className="rx-header">
                <h3 className="rx-name">{formatRXName(restaurant.consumerAppDisplayName || '')}</h3>
                <RXConfig config={restaurant} />
              </div>
            </div>
          </div>
        </div>
      </ConditionalWrapper>
      {useOrderPage ?
        <a href={ooURLTracking} className="link-to-rx-profile">More about {restaurant.consumerAppDisplayName ? formatRXName(restaurant.consumerAppDisplayName) : 'this restaurant'}</a> :
        <Link to={`/local${restaurant.shortUrl ? `/${restaurant.shortUrl}` : ''}/r-${restaurant.guid}`} className="link-to-rx-profile">
          More about {restaurant.consumerAppDisplayName ? formatRXName(restaurant.consumerAppDisplayName) : 'this restaurant'}
        </Link>}
    </div>
  );
};


export default RXCard;
