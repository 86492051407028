import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';

import { EditableComponentName, FieldType, ModuleConfig, ModuleField, useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import debounce from 'lodash/debounce';

import { BannerPlacement, Image as ImageObject, LogoSizeOption } from 'src/apollo/sites';
import { useIsIntlRestaurant } from 'src/lib/js/hooks/useIsIntlRestaurant';
import useOnScroll from 'src/lib/js/hooks/useOnScroll';
import { ScrollDirection } from 'src/lib/js/hooks/useScrollDirection';

import GlobalNav, { NavType } from 'shared/components/common/nav/Nav';
import { Restaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import { EditablePrimaryCTA, PrimaryCTAClasses } from 'shared/components/primary_cta/PrimaryCTA';

import { AdditionalCTAData, CTA, CTAData } from 'public/components/default_template/ctas';
import CTAItem from 'public/components/default_template/ctas/CTAItem';
import CartModal from 'public/components/default_template/online_ordering/cart/CartModal';
import { LegacyDiningBehaviorToggle } from 'public/components/default_template/online_ordering/dining_behavior_toggle/FulfillmentControl';
import OrderOptions from 'public/components/default_template/online_ordering/order_options/OrderOptions';
import SpotlightBanner, { showSpotlight } from 'public/components/default_template/spotlight/SpotlightBanner';
import { useSpotlightContext } from 'public/components/default_template/spotlight/SpotlightContext';

import { ResponsiveNavItems } from './NavItems';
import NavLogo from './NavLogo';
import UserNav from './UserNav';
import { getNavColorStyles, getNavOpacity } from './navUtils';

export type CommonNavProps = {
  shouldShowPreviewBanner: boolean;
  cartDisabled?: boolean;
  className?: string;
  logoSize?: LogoSizeOption | null;
  logoSrc: string;
  logoObject?: ImageObject | null;
  navType?: NavType | null;
  nonPrimaryCtas?: CTAData[] | null;
  withCart?: boolean;
  withDiningToggle?: boolean;
  withOOOptions?: boolean;
  withShadow?: boolean;
  // certain pages (online ordering) should always display toast login
  forceDisplayToastLogin?: boolean;
};

type NavContentProps = CommonNavProps & {
  restaurant?: Restaurant;
  scrollDirection?: ScrollDirection;
  ctaProps?: AdditionalCTAData;
  primaryCtaContent?: CTA | null;
  setDisplayingOverlay?: (d: boolean) => void;
  logoLink?: string;
  logoLinkSameTab?: boolean;
};

type WrappedNavContentProps = NavContentProps & {
  route: string;
};

type PrimaryCTAFactory = ({ excludeOOOptions }: {
  excludeOOOptions?: boolean
  excludeCart?: boolean
}) => JSX.Element | null

export const getNavSchema = (restaurant: Restaurant | undefined) => {
  const style = getNavColorStyles(restaurant, true);
  const navConfig = restaurant?.content?.navConfig;
  const navLayout = navConfig?.navLayout || 'header-layout-1';
  const [bgColorPath, textColorPath] = restaurant?.theme?.enabled
    ? ['theme.colorOverrides.navigation.background', 'theme.colorOverrides.navigation.text']
    : ['content.navConfig.backgroundColor', 'content.navConfig.textColor'];
  return {
    name: 'navigation bar' as EditableComponentName,
    displayName: 'Nav Bar',
    actions: [],
    schema: {
      fields: [
        {
          displayName: 'Background color',
          path: bgColorPath,
          type: FieldType.BackgroundColor,
          value: style.backgroundColor
        },
        {
          displayName: 'Text color',
          path: textColorPath,
          type: FieldType.Color,
          value: style.color
        },
        {
          displayName: 'Opacity',
          path: 'content.navConfig.opacity',
          type: FieldType.Slider,
          value: navConfig?.opacity
        },
        {
          displayName: 'Overlaid',
          path: 'content.navConfig.overlaid',
          type: FieldType.Boolean,
          value: navConfig?.overlaid
        },
        {
          displayName: 'Sticky',
          path: 'content.navConfig.sticky',
          type: FieldType.Boolean,
          value: navConfig?.sticky
        },
        {
          displayName: 'Primary CTA',
          path: 'content.primaryCta',
          type: FieldType.Boolean,
          value: !!restaurant?.content?.primaryCta,
          activeValue: {
            nav: true,
            text: 'View Menu',
            type: 'menu',
            link: '/menu',
            submenus: []
          }
        },
        // the alwaysShowPrimaryCTA should only show if it is a hamburger nav layout and if primaryCTA is on
        ... !!restaurant?.content?.primaryCta && isHamburgerAlwaysVisibleInLayout(navLayout) ?
          [
            {
              displayName: 'Always Show Primary CTA',
              path: 'content.navConfig.alwaysShowPrimaryCTA',
              type: FieldType.Boolean,
              value: navConfig?.alwaysShowPrimaryCTA && !!restaurant?.content?.primaryCta
            } as ModuleField
          ]
          : [],
        {
          displayName: 'Show Toast Login',
          path: 'content.navConfig.showToastLogin',
          type: FieldType.Boolean,
          value: navConfig?.showToastLogin ?? true
        }
      ]
    } as ModuleConfig
  };
};

const WrappedNavContent = (props: React.PropsWithChildren<WrappedNavContentProps>) => {
  const { useEditableRef } = useEditor();
  // After the logo loads, rerender the parent of ResponsiveNavItems
  // so that ResponsiveNavItems will resize to fit the screen
  const [logoLoaded, setLogoLoaded] = useState(false);
  const onLogoLoad = useCallback(() => setLogoLoaded(true), []);
  const [scrolledToTop, setScrolledToTop] = useState(true);
  const { isHidden } = useSpotlightContext();
  const isIntlRestaurant = useIsIntlRestaurant();
  const { intlOoGuestAccounts: intlGuestAccounts, ooNewFulfillmentToggleOct24 } = useFlags();

  let style = getNavColorStyles(props.restaurant, true);
  const hamburgerStyle = { fill: style.color };

  const navConfig = props.restaurant?.content?.navConfig;
  let navType = props.navType;
  if(!navType) {
    if(navConfig?.overlaid) {
      if(navConfig?.sticky === false) {
        navType = 'absoluteNav';
      } else {
        navType = 'fixedNav';
      }
    } else if(navConfig?.sticky === false) {
      navType = 'normal';
    } else {
      navType = 'stickyNav';
    }
  }
  const navLayout = navConfig?.navLayout || 'header-layout-1';

  const scrollReponsiveNav = navType === 'fixedNav' || navType === 'stickyNav';
  if(scrollReponsiveNav && !scrolledToTop && getNavOpacity(navConfig) === 0) {
    const stylesWithoutOpacity = getNavColorStyles(props.restaurant, false);
    style.backgroundColor = stylesWithoutOpacity.backgroundColor;
  }

  const toggleMenu = useCallback(() => {
    // toggle the menu
    props.setDisplayingOverlay!(true);
  }, [props.setDisplayingOverlay]);

  // to make the hamburger more accessible for keyboard access
  const handleKeyDown = useCallback((e: React.KeyboardEvent) => {
    if(e.key === ' ' || e.key === 'Spacebar' || e.key === 'Enter') {
      e.preventDefault(); // Prevent the default space key action (page scroll)
      toggleMenu();
    }
  }, [toggleMenu]);

  const debouncedScrollUpdate = debounce(() => setScrolledToTop(window.pageYOffset === 0), 25);
  useOnScroll(() => debouncedScrollUpdate());

  const navSchema = useMemo(() => {
    return getNavSchema(props.restaurant);
  }, [props.restaurant]);
  const { editableRef: editableNavBarRef, pushSchema } = useEditableRef<HTMLDivElement>(navSchema);
  useEffect(() => {
    // resubmit the changed schema when primaryCTA toggle changes.
    pushSchema();
  }, [pushSchema, props.restaurant?.content?.primaryCta]);
  const { editableRef: editableNavListRef } = useEditableRef<HTMLDivElement>({ name: 'navigation list', displayName: 'Nav List', actions: [], schema: { fields: [] } });
  const { editableRef: editableHamburgerRef } = useEditableRef<HTMLDivElement>({ name: 'navigation list', displayName: 'Nav List', actions: [], schema: { fields: [] } });

  const showFixedTopSpotlight =
    props.restaurant?.content?.spotlightBanner && showSpotlight({ fixed: true, placement: BannerPlacement.Top }, props.restaurant.content.spotlightBanner, `/${props.route.split('/')[1]}`);

  const hamburger =
    props.nonPrimaryCtas?.length && props.setDisplayingOverlay && !isIntlRestaurant
      ?
      <div
        className={classnames('hamburger', { 'hidden-md-up': !isHamburgerAlwaysVisibleInLayout(navLayout) })}
        onClick={() => toggleMenu()}
        onKeyDown={handleKeyDown}
        nav-role="hamburger-button"
        data-testid="hamburger-button"
        tabIndex={0}
        ref={editableHamburgerRef}>
        <svg viewBox="0 0 100 80" width="20" height="20" {...hamburgerStyle}>
          <rect width="100" height="15"></rect>
          <rect y="30" width="100" height="15"></rect>
          <rect y="60" width="100" height="15"></rect>
        </svg>
      </div>
      : null;

  const logo =
    <div data-testid="logoLink" nav-role="logo">
      <NavLogo
        logoLink={props.logoLink}
        logoLinkSameTab={props.logoLinkSameTab}
        style={style}
        onLoad={onLogoLoad}
        logoSrc={props.logoSrc}
        logoObject={props.logoObject}
        logoSize={props.logoSize || navConfig?.logoSize}
        scrollDirection={scrollReponsiveNav ? props.scrollDirection : undefined} />
    </div>;
  const diningBehaviorToggle = props.withDiningToggle ? <LegacyDiningBehaviorToggle className="behaviorToggle hidden-lg-down" saveChange withSpinner /> : null;

  const links = props.ctaProps
    ?
    <div className={classnames('hidden-sm-down', { center: props.withOOOptions })} nav-role="links">
      <div className="navLinks" ref={editableNavListRef} key={`nav-links-${logoLoaded}`}>
        <ResponsiveNavItems ctas={props.nonPrimaryCtas} ctaProps={props.ctaProps} parentRef={editableNavListRef} moreMenuLabel={navConfig?.moreMenuLabel} color={navConfig?.textColor} />
      </div>
    </div>
    : null;

  const primaryCTA: PrimaryCTAFactory = useCallback(({ excludeOOOptions = false, excludeCart = false }) =>
    <div nav-role="primary-cta">
      {props.withOOOptions && !excludeOOOptions && !ooNewFulfillmentToggleOct24 ?
        <li>
          <OrderOptions style={style} className="hidden-sm-down" modalTestId="primary-cta-oo-options-modal" buttonTestId="primary-cta-oo-options-btn" />
        </li> :
        null}
      {props.withCart && !excludeCart ? <li><CartModal disabled={props.cartDisabled} /></li> : null}
      {props.primaryCtaContent
        ?
        <li className="cta hidden-sm-down">
          <CTAItem cta={props.primaryCtaContent} isPrimary className={PrimaryCTAClasses} editPath="content.primaryCta">
            <EditablePrimaryCTA editPath="content.primaryCta" href={props.primaryCtaContent?.link} text={props.primaryCtaContent?.text} type={props.primaryCtaContent.type} />
          </CTAItem>
        </li>
        : null}
      {!isIntlRestaurant || intlGuestAccounts ? <li><UserNav forceDisplayToastLogin={props.forceDisplayToastLogin} /></li> : null}
    </div>, [isIntlRestaurant, intlGuestAccounts, props.cartDisabled, props.primaryCtaContent, props.withCart, props.withOOOptions, props.forceDisplayToastLogin, style, ooNewFulfillmentToggleOct24]);

  const ooOptions =
    props.withOOOptions || props.withCart
      ?
      <li nav-role="primary-cta">
        {props.withOOOptions && !ooNewFulfillmentToggleOct24 ? <OrderOptions style={style} className="hidden-sm-down" modalTestId="oo-options-modal" buttonTestId="oo-options-btn" /> : null}
        {props.withCart ? <CartModal disabled={props.cartDisabled} /> : null}
      </li>
      : null;
  const alwaysPrimaryCTA: PrimaryCTAFactory = useCallback(({ excludeOOOptions = false, excludeCart = false }) =>
    navConfig?.alwaysShowPrimaryCTA && !!props.restaurant?.content?.primaryCta ?
      <div data-testid="alwaysShowPrimaryCta">{primaryCTA({ excludeOOOptions, excludeCart })}</div> :
      null,
  [navConfig?.alwaysShowPrimaryCTA, primaryCTA, props.restaurant?.content?.primaryCta]);
  const navChildren = organizeNavChildren(
    {
      hamburger,
      logo,
      links,
      primaryCTA,
      diningBehaviorToggle,
      ooOptions,
      alwaysPrimaryCTA
    },
    navLayout
  );
  return (
    <>
      {navType === 'stickyNav' && <SpotlightBanner placement={BannerPlacement.Top} fixed={false} />}
      {!scrollReponsiveNav && <SpotlightBanner placement={BannerPlacement.Top} fixed={true} />}
      <GlobalNav navType={navType}
        shouldShowPreviewBanner={props.shouldShowPreviewBanner}
        className={classnames(props.className, { spotlightOffset: showFixedTopSpotlight && !isHidden })}>
        {navType !== 'stickyNav' && <SpotlightBanner placement={BannerPlacement.Top} fixed={false} style={navType === 'fixedNav' && !scrolledToTop ? { height: 0 } : {}} />}
        {scrollReponsiveNav && <SpotlightBanner placement={BannerPlacement.Top} fixed={true} />}
        <div className={classnames('navWrapper', { withShadow: props.withShadow })} style={style} ref={editableNavBarRef}>
          <ul className={classnames('nav', navLayout)}>{navChildren}</ul>
        </div>
        {props.children}
        {navType !== 'stickyNav' && <SpotlightBanner placement={BannerPlacement.UnderHeader} fixed={false} />}
        {/* a fixed spotlight banner currently only works if the nav is sticky or fixed */}
        <SpotlightBanner placement={BannerPlacement.UnderHeader} fixed={true} />
      </GlobalNav>
      {navType === 'stickyNav' && <SpotlightBanner placement={BannerPlacement.UnderHeader} fixed={false} />}
    </>
  );
};

const EditorNavContent = (props: NavContentProps) => {
  const { url } = useEditor();
  return <WrappedNavContent {...props} route={url} />;
};

const SitesNavContent = (props: NavContentProps) => {
  const { pathname } = useLocation();
  return <WrappedNavContent {...props} route={pathname} />;
};

const NavContent = (props: NavContentProps) => {
  const { isEditor } = useEditor();
  return isEditor ? <EditorNavContent {...props} /> : <SitesNavContent {...props} />;
};

export const isHamburgerAlwaysVisibleInLayout = (layout: string): boolean => {
  switch(layout) {
    case 'header-layout-3':
    case 'header-layout-6':
    case 'header-layout-7':
      return true;
    default:
      return false;
  }
};


type NavChildrenType = {
  logo: ReactNode;
  hamburger: ReactNode;
  primaryCTA: PrimaryCTAFactory;
  links: ReactNode;
  diningBehaviorToggle: ReactNode;
  ooOptions: ReactNode;
  alwaysPrimaryCTA: PrimaryCTAFactory;
};

export const organizeNavChildren = ({ hamburger, logo, diningBehaviorToggle, links, primaryCTA, ooOptions, alwaysPrimaryCTA }: NavChildrenType, layout: string): ReactNode => {
  switch(layout) {
    case 'header-layout-8':
      return (
        <>
          <div className="left">
            {hamburger}
          </div>
          <div className="middle">
            {logo}
            {links}
          </div>
          <div className="right">{primaryCTA({})}</div>
        </>
      );
    case 'header-layout-7':
      return (
        <>
          <div className="left"> </div>
          <div className="middle">{logo}</div>
          <div className="right">
            {ooOptions}
            {alwaysPrimaryCTA({ excludeOOOptions: true, excludeCart: true })}
            {hamburger}
          </div>
        </>
      );
    case 'header-layout-6':
      return (
        <>
          <div className="left">
            {hamburger}
            {alwaysPrimaryCTA({ excludeOOOptions: true, excludeCart: true })}
          </div>
          <div className="middle">{logo}</div>
          <div className="right">{ooOptions}</div>
        </>
      );
    case 'header-layout-5':
      return (
        <>
          <div className="left"> </div>
          <div className="middle">{logo}</div>
          <div className="right">
            {links}
            {diningBehaviorToggle}
            {primaryCTA({})}
            {hamburger}
          </div>
        </>
      );
    case 'header-layout-4':
      return (
        <>
          <div className="left">
            {hamburger}
            {links}
            {diningBehaviorToggle}
          </div>
          <div className="middle">{logo}</div>
          <div className="right">{primaryCTA({})}</div>
        </>
      );
    case 'header-layout-3':
      return (
        <>
          <div className="left">{logo}</div>
          <div className="middle"></div>
          <div className="right">
            {ooOptions}
            {alwaysPrimaryCTA({ excludeOOOptions: true, excludeCart: true })}
            {hamburger}
          </div>
        </>
      );
    case 'header-layout-2':
    case 'header-layout-1':
    default:
      return (
        <>
          <div className="left">
            {hamburger}
            {logo}
            {diningBehaviorToggle}
          </div>
          <div className="middle">{links}</div>
          <div className="right">{primaryCTA({})}</div>
        </>
      );
  }
};

export default NavContent;
