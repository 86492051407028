import { getCookie } from 'react-use-cookie';

import { ApolloClient, InMemoryCache, NormalizedCacheObject, ApolloLink, HttpLink } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import * as Sentry from '@sentry/react';
import crossFetch from 'cross-fetch';
import Observable from 'zen-observable';

import { ClientSession } from 'src/core/sessionTokenService';
import { getSource } from 'src/shared/components/common/authentication';
import { PWLESS_ACCESS, PWLESS_REFRESH, PW_ACCESS } from 'src/shared/components/common/authentication/constants';
import { isGuestProfileCreated, setTokens } from 'src/shared/js/authUtils';
import { getAuthenticationHeader } from 'src/vendor/do-secundo-guest-authentication';

import { resources } from 'config';

import { PasswordlessRefreshTokenDocument } from './onlineOrdering';
import ooIntrospectionQueryData from './ooPossibleTypes';
import introspectionQueryData from './sitesPossibleTypes';
import TimeoutLink from './timeoutLink';

declare global {
  interface Window {
    __TOAST_BANQUET_INITIAL_DATA__?: {
      session?: ClientSession
    }
  }
}

// Observable needed to get promises to work in onError
// https://github.com/apollographql/apollo-link/issues/646
const promiseToObservable = (promise: Promise<any>) => new Observable((subscriber: any) => {
  promise.then(
    value => {
      if(!subscriber.closed) {
        if(value?.data?.passwordlessRefreshToken?.code === 'UNKNOWN_OR_INVALID_REFRESH') {
          subscriber.error(value);
        } else {
          subscriber.next(value);
          subscriber.complete();
        }
      }
    },
    err => subscriber.error(err)
  );
});

const hasAuthError = (graphQLErrors: any) => graphQLErrors && graphQLErrors.findIndex((err: any) => err?.extensions?.code === 'UNAUTHENTICATED') !== -1;
const handleAuthError = (operation: any, forward: any) => promiseToObservable(refreshAuthToken()).flatMap(value => {
  // attach the refreshed auth token so that the old token isn't reused when retrying the query
  if(value) {
    let headers = operation.getContext().headers;
    headers['Authorization'] = `Bearer ${value}`;
    operation.setContext({ headers });
  }
  return forward(operation);
});

// @ts-ignore
export const serverErrorLink = onError(({ response, networkError, graphQLErrors, operation, forward }) => {
  if(graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      if(response) {
        // @ts-ignore
        response.errorMessage = message;
      }
      console.error(`[Request error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
    });
  }
  if(networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
  if(hasAuthError(graphQLErrors)) {
    return handleAuthError(operation, forward);
  }
});

// @ts-ignore
const clientErrorLink = onError(({ networkError, graphQLErrors, operation, forward }) => {
  if(networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
  if(hasAuthError(graphQLErrors)) {
    return handleAuthError(operation, forward);
  }
});

// @ts-ignore
const giaClientErrorLink = onError(({ networkError, graphQLErrors, operation, forward }) => {
  if(networkError) {
    console.error(`[Network error]: ${networkError.message}`, networkError);
  }
  // Client should handle refreshing the token
});

const legacyAuthLink = setContext((_, { headers }) => {
  const pwlessAccessToken = getCookie(PWLESS_ACCESS);
  const guestProfileCreated = isGuestProfileCreated(pwlessAccessToken);
  const pwAccessToken = getCookie(PW_ACCESS);

  const newHeaders: { [key: string]: string } = {};
  // A value of null for guestProfileCreated indicates a bad token. It will get refreshed by logic
  // in CustomerContext.
  if(pwlessAccessToken && guestProfileCreated) {
    newHeaders['Authorization'] = `Bearer ${pwlessAccessToken}`;
  }
  if(pwAccessToken) {
    newHeaders['toast-customer-access'] = pwAccessToken;
  }

  return {
    headers: {
      ...headers,
      ...newHeaders
    }
  };
});

const giaAuthLink = setContext(async (_, { headers: originalHeaders }) => {
  const authHeader = await getAuthenticationHeader();
  const headers = authHeader
    ? { [authHeader.key]: authHeader.value }
    : {};

  return {
    headers: {
      ...originalHeaders,
      ...headers
    }
  };
});

const securityTokenHeaderLinkFactory = (sessionToken: string) => setContext((_, { headers: originalHeaders }) => {
  return {
    headers: {
      ...originalHeaders,
      'Toast-Session-ID': sessionToken
    }
  };
});

const getSessionToken = () => {
  try {
    const tokenObj = typeof document !== 'undefined' ? document.getElementById('session')?.getAttribute('data-content') : null;

    if(!tokenObj) {
      // Try to resolve session for toastweb
      return typeof window !== 'undefined' ? window.__TOAST_BANQUET_INITIAL_DATA__?.session?.id : undefined;
    }

    return tokenObj ? JSON.parse(atob(tokenObj))?.id : undefined;
  } catch(e) {
    return undefined;
  }
};

/**
  * Creates an Apollo client. Specifically, clients created using this method
  * will use an auth token fetched from the browser's cookies before every request.
  *
  * @param {object} state - If desired, the cache can be hydrated with a prefetched state.
  * @return {object} An `ApolloClient` instance suited for requests from a browser client.
  */
export const createClient = (
  uri = resources.apiEndpoint,
  state: NormalizedCacheObject | undefined = undefined,
  includeCredentials?: boolean,
  preventBatch?: boolean,
  additionalHeaders?: Record<string, string>,
  withRetry?: boolean,
  withGiaAuth: boolean = false,
  timeout?: number
) => {
  const isSsr = typeof window === 'undefined';
  const cache = new InMemoryCache({
    possibleTypes: { ...introspectionQueryData.possibleTypes, ...ooIntrospectionQueryData.possibleTypes },
    typePolicies: {
      Restaurant: {
        merge: true,
        fields: {
          config: { merge: true },
          pixelsV2: { merge: true },
          socialMediaLinks: { merge: true }
        }
      },
      RestaurantConfig: { merge: true },
      MenuConfig: { merge: true },
      OnlineOrderingConfig: { merge: true },
      Image: { merge: true },
      MenuFormatConfig: { merge: true },
      ColorConfig: { merge: true },
      SiteContent: { merge: true },
      Query: {
        fields:
        {
          offers: { merge: true },
          menusV3: { keyArgs: ['input', ['restaurantGuid', 'respectAvailability', 'hideOutOfStockItems', 'channelGuid']] },
          popularItems: { keyArgs: ['input', ['restaurantGuid']] },
          rankedPromoOffers: { keyArgs: ['input', ['restaurantGuid']] }
        }
      }
    }
  });

  // define a customFetch to override cross-fetch to decorate the outbound request
  // with all batched graphQL operations
  const customFetch = (uri: any, options: any) => {
    try {
      // Parse the body to get the operations
      const body = JSON.parse(options.body);
      let operationNames = '';
      if(Array.isArray(body)) {
        operationNames = body
          .map((op: { operationName: any; }) => op.operationName || 'UnnamedOperation')
          .sort() // sort these operations alphabetically in ascending order
          .join(',');
      } else if('operationName' in body) {
        operationNames = body.operationName;
      }

      // Modify the request headers to include the operation names for every graphQL operation (query/mutation)
      options.headers = {
        ...options.headers,
        'Toast-GraphQL-Operation': operationNames
      };
    } catch(error) {
      // if this decoration fails for ANY reason make sure that the request is never blocked, just move along
      Sentry.captureException(`ERROR: an error occurred decorating apollo request with headers ${error}`);
    }
    // Now call cross-fetch with the modified options
    return crossFetch(uri, options);
  };

  const linkOptions = {
    fetch: customFetch,
    uri,
    credentials: includeCredentials ? 'include' : 'none',
    headers: additionalHeaders || undefined
  };

  const httpLink = preventBatch ?
    new HttpLink(linkOptions) :
    new BatchHttpLink(linkOptions);

  const configuredClientErrorLink = withGiaAuth
    ? giaClientErrorLink
    : clientErrorLink;

  const errorLink = isSsr
    ? serverErrorLink
    : configuredClientErrorLink;

  const links = [errorLink, httpLink];
  const retryLink = withRetry ?
    ApolloLink.from([
      new RetryLink({
        delay: { initial: 200, max: 1000, jitter: true },
        attempts: {
          max: 4,
          retryIf: (error, _operation) => {
            return error?.message === 'Network request failed';
          }
        }
      }),
      ...links
    ]) :
    ApolloLink.from(links);

  const sessionToken = getSessionToken();
  const sessionLink = sessionToken ? securityTokenHeaderLinkFactory(sessionToken) : null;

  let authedLink = null;
  if(includeCredentials) {
    const authLink = withGiaAuth ? giaAuthLink : legacyAuthLink;
    const secLink = sessionLink ? authLink.concat(sessionLink) : authLink;
    authedLink = secLink.concat(retryLink);
  } else {
    authedLink = sessionLink ? sessionLink.concat(retryLink) : retryLink;
  }

  const timeoutLink = new TimeoutLink(timeout);
  authedLink = timeoutLink.concat(authedLink);

  return new ApolloClient({
    ssrMode: isSsr,
    link: authedLink,
    cache: state ? cache.restore(state) : cache,
    name: process.env.toast_svc_name || 'sites-web-client',
    version: process.env.toast_service_revision || process.env.VERSION || 'unknown'
  });
};


// Don't include the access token auth header (i.e. set includeCredentials to false) in this client. If refreshing, the token might be malformed, which can cause an error.
const authRefreshClient = createClient(resources.federatedProxyHost, undefined, false, true, undefined, false, false, resources.clientQueryTimeoutMs);
export const refreshAuthToken = async (onSetTokens?: (accessToken: string) => void) => {
  const currRefresh = getCookie(PWLESS_REFRESH);

  if(!currRefresh) {
    return null;
  }

  const result = await authRefreshClient.mutate({
    mutation: PasswordlessRefreshTokenDocument,
    // refreshToken is populated by the server
    variables: { input: { refreshToken: currRefresh, source: getSource() } }
  });

  if(result.data?.passwordlessRefreshToken?.__typename === 'PasswordlessTokenResponse') {
    setTokens(result.data.passwordlessRefreshToken.accessToken, result.data.passwordlessRefreshToken.expiresAtIso8601, result.data.passwordlessRefreshToken.refreshToken, onSetTokens);
    return result.data.passwordlessRefreshToken.accessToken;
  }

  return null;
};
