import React, { useCallback, useState } from 'react';

import classNames from 'classnames';

import { RankedPromoOfferDiscount } from 'src/apollo/onlineOrdering';
import useTracker from 'src/lib/js/hooks/useTracker';
import { formatPrice } from 'src/lib/js/utilities';
import { DEFAULT_COLORS } from 'src/public/components/default_template/meta/StyleMeta';
import { TagIcon } from 'src/public/components/default_template/offers/OffersIcons';
import { CART_POPOVER_CONTEXT_KEY } from 'src/public/components/default_template/online_ordering/cart/CartModal';
import { useCart } from 'src/public/components/online_ordering/CartContext';
import { useOffers } from 'src/public/components/online_ordering/OffersContext';
import { Modal, ModalOverlay, ModalContent, ModalCloseButton } from 'src/shared/components/common/modal';
import { usePopoverContext } from 'src/shared/components/common/popover/PopoverContext';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import OfferItemsGrid, { FullMenuOffer, MenuGroupsList } from './OfferItems';
import { getTopOffer, OfferBannerState } from './OfferUtils';

export const CartOffer = () => {
  const { applyPromoCode, loadingCart, cart } = useCart();
  const { rankedPromoOfferDiscounts } = useOffers();
  const [isOfferModalOpen, setIsOfferModalOpen] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState<RankedPromoOfferDiscount | undefined>(undefined);
  const cartPopoverContext = usePopoverContext(CART_POPOVER_CONTEXT_KEY);
  const onClickOffer = useCallback(async (offer : RankedPromoOfferDiscount) => {
    const itemDiscountWithoutDiscountedItems = offer.discount?.selectionType === 'ITEM' && !offer.discountEntitiesClaimed;
    const bogoWithoutDiscountedItems = offer.discount?.bogoAction !== null && offer.discount?.bogoAction !== undefined && offer.percentCompleteInt === 75;
    if(itemDiscountWithoutDiscountedItems || bogoWithoutDiscountedItems) {
      setSelectedOffer(offer);
      setIsOfferModalOpen(true);
    } else {
      await applyPromoCode(offer.promoCode);
    }
  }, [applyPromoCode]);

  const onCloseOfferModal = useCallback((openCart: boolean = true) => {
    setSelectedOffer(undefined);
    setIsOfferModalOpen(false);
    if(openCart) {
      cartPopoverContext?.open();
    }
  }, [cartPopoverContext]);
  const currentAppliedOffer = cart?.order?.discounts?.restaurantDiscount?.promoCode;

  if(loadingCart || !cart?.order?.selections?.length || !rankedPromoOfferDiscounts?.length ) {
    return null;
  }

  const topOffer = getTopOffer(rankedPromoOfferDiscounts, currentAppliedOffer);

  if(!topOffer) {
    return null;
  }

  return (
    <div data-testid="cart-offers" className="cartOffers" tabIndex={0}>
      <OfferStatusCard
        offer={topOffer}
        onClickOffer={onClickOffer}
        currentAppliedOffer={currentAppliedOffer} />
      <OfferItemModal isOpen={isOfferModalOpen} onClose={onCloseOfferModal} offer={selectedOffer} />
    </div>
  );
};

type OfferItemModalProps = {
  isOpen: boolean;
  onClose: () => void;
  offer: RankedPromoOfferDiscount | undefined;
};

const OfferItemModal = ({ isOpen, onClose, offer }: OfferItemModalProps) => {
  if(offer?.discount?.selectionType !== 'ITEM') {
    return null;
  }
  const useOfferItemsGrid: boolean = offer.discountEntities?.items ? offer.discountEntities.items.length > 0 : false;
  const hasGroups: boolean = offer.discountEntities?.groups ? offer.discountEntities.groups.length > 0 : false;
  const hasMenus: boolean = offer.discountEntities?.menus ? offer.discountEntities.menus.length > 0 : false;
  const useMenuGroupsList: boolean = hasGroups || hasMenus;

  const useFullMenu = offer.discountEntities?.items?.length === 0 && offer.discountEntities?.groups?.length === 0 && offer.discountEntities?.menus?.length === 0;
  const message = useFullMenu ?
    'This offer applies to all menu items. Visit the menu and add any item to your cart to claim the discount.' :
    'Choose any item from below or from any eligible menu categories.';
  return (
    <Modal isOpen={isOpen} onClose={onClose} testId="offer-items-modal">
      <ModalOverlay fadeIn />
      <ModalContent contentClassName={classNames('offerItemModal', useFullMenu ? 'fullMenuModal' : '')} wrapperClassName="offerItemModalWrapper">
        <div className="header">
          <ModalCloseButton />
        </div>
        <div className="content">
          <div style={{
            fontSize: '20px',
            fontWeight: 700
          }}>
            {
              offer.discount.bogoAction?.amountType === 'PERCENT' && offer.discount.bogoAction?.discountValue === 100
                ? 'Claim your free item'
                : 'Claim your discounted item'
            }
          </div>
          <p>{message}</p>
          {useOfferItemsGrid ? <OfferItemsGrid offer={offer} closeOfferItems={onClose} /> : null}
          {useFullMenu ? <FullMenuOffer onClose={onClose} /> : null}
          {useMenuGroupsList ?
            <MenuGroupsList menuGroupGuids={offer.discountEntities?.groups ?? []} menuGuids={offer.discountEntities?.menus ?? []} onClose={onClose} />
            : null}
        </div>
      </ModalContent>
    </Modal>
  );
};

type OfferStatusCardProps = {
  offer: RankedPromoOfferDiscount | undefined;
  onClickOffer: (offer: RankedPromoOfferDiscount) => void;
  currentAppliedOffer?: string;
};
const OfferStatusCard = ({ offer, onClickOffer, currentAppliedOffer }: OfferStatusCardProps) => {
  const { restaurant } = useRestaurant();
  const tracker = useTracker();
  const { cart } = useCart();

  if(!offer || !offer.discount) {
    return null;
  }
  const saveAmountStr = offer.discount?.amountType === 'PERCENT' ? `${offer.discount?.discountValue}%` : formatPrice(offer.discount?.discountValue ?? 0);
  const applied = currentAppliedOffer === offer.promoCode;
  const bogoAction = offer.discount.bogoAction;
  let addStr: string | undefined;
  const tagColor = applied ? '#408140' : restaurant.meta.primaryColor ?? DEFAULT_COLORS.primary;
  let icon = <TagIcon color={tagColor} />;
  let buttonText = 'Apply Promo';
  if(offer.discount?.minSpendAmount !== undefined) {
    if( offer.discount.selectionType === 'ITEM' ) {
      const itemDescriptor = offer.discount.discountValue === 100 && offer.discount.amountType === 'PERCENT' ? 'free' : 'discounted';
      if(bogoAction !== null && bogoAction !== undefined) {
        addStr = offer.percentCompleteInt >= 75 ?
          `Claim your ${itemDescriptor} item`
          : `Add ${formatPrice(offer.amountNeeded)} to get a ${itemDescriptor} item`;
        buttonText = offer.percentCompleteInt === 100 ? 'Apply Promo' : 'Choose item';
      } else {
        addStr = offer.percentCompleteInt === 100 ?
          `Claim your ${itemDescriptor} item`
          : `Add ${formatPrice(offer.amountNeeded)} to get a ${itemDescriptor} item`;
        buttonText = offer.discountEntitiesClaimed ? 'Apply Promo' : 'Choose item';
      }
    } else {
      addStr = `Add ${formatPrice(offer.amountNeeded)} to save ${saveAmountStr}`;
    }
  }
  if(applied) {
    addStr = `Saving ${formatPrice(cart?.order?.discountsTotal ?? offer.estimatedSavings)} on this order!`;
  }

  const bogoWithoutDiscountedItems = bogoAction !== null && bogoAction !== undefined && offer.percentCompleteInt >= 75;
  const itemDiscountWithoutDiscountedItems = offer.discount.selectionType === 'ITEM' && !offer.discountEntitiesClaimed;
  const canBeApplied = offer.percentCompleteInt === 100 || bogoWithoutDiscountedItems;

  const bannerState = () => {
    if(buttonText.includes('Choose item')) {
      return OfferBannerState.ADD_ITEM;
    } else if(canBeApplied && !applied || bogoWithoutDiscountedItems || itemDiscountWithoutDiscountedItems) {
      return OfferBannerState.ACHIEVED;
    }
    return applied ? OfferBannerState.APPLIED : OfferBannerState.PROGRESS;
  };

  tracker.track('temp_CartViewedWithBanner',
    {
      progressBannerPercentage: offer.percentCompleteInt,
      bannerState: bannerState(),
      promoCode: offer.promoCode,
      promoName: offer.discount.name,
      promoType: offer.discount.amountType,
      promoApplied: applied,
      numItems: cart?.order?.numberOfSelections,
      subtotal: cart?.order?.preDiscountItemsSubtotal,
      tax: cart?.order?.taxV2,
      discounts: cart?.order?.discountsTotal,
      total: cart?.order?.totalV2
    });
  return (
    <button onClick={() => onClickOffer(offer)} disabled={!canBeApplied || applied} className="offerStatusWrapper" type="button">
      {/* Using canBeApplied here as the BOGO percentCompleteInt is actually 75% but we want it to appear as 100% */}
      {!applied ? <PercentBar percent={canBeApplied ? 100.0 : offer.percentCompleteInt} /> : null}
      <div data-testid="offer-status" className={classNames('offerStatus', applied ? 'applied' : null)}>
        <div className="offerDesc">
          {icon}
          {addStr ? addStr : null}
        </div>
        {canBeApplied === true && !applied ? <div>{buttonText}</div> : null}
      </div>
    </button>
  );
};

const PercentBar = ({ percent }: {percent?: number}) => {
  if(!percent) {
    return null;
  }
  return (
    <div className="percentBar" data-testid="percent-bar">
      <div className="percentFill" style={{ width: `${percent}%` }} />
    </div>
  );
};

export default CartOffer;
