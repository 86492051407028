import React from 'react';
import { Tooltip } from 'react-tooltip';

import { useOffers } from 'src/public/components/online_ordering/OffersContext';
import Image from 'src/shared/components/common/Image';

export const DiscountsInfoToolTip = () => {
  const { hasAutoAppliedPromo } = useOffers();
  if(!hasAutoAppliedPromo) {
    return null;
  }
  return (
    <div>
      <div id="discounts-info" className="infoIconWrapper" aria-describedby="discounts-info-tooltip" data-testid="discounts-tooltip">
        <Image src="icons/info.svg" className="infoIcon" />
      </div>
      <Tooltip
        id="discounts-info-tooltip"
        anchorSelect="#discounts-info"
        content="We've applied your first applicable offer! Choose between available offers at checkout."
        // className="tooltipContent"
        style={{ width: '260px' }}
        place={'right'} />
    </div>
  );
};
