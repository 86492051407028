import React, { useCallback, useMemo } from 'react';

import { ButtonType, CtaType } from 'src/apollo/sites';
import useRefSize from 'src/lib/js/hooks/useRefSize';
import Button from 'src/shared/components/common/button';
import { formatAddress } from 'src/shared/components/common/form_input/LocationInput';

import Image from 'shared/components/common/Image';
import AttributionContextProvider from 'shared/components/common/attribution_context/AttributionContext';
import DropDown from 'shared/components/common/dropdown';
import Link from 'shared/components/common/link';
import { useOptionalRestaurant, useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import useMoreItems, { MoreItemRefs } from 'shared/js/hooks/useMoreItems';

import { AdditionalCTAData, CTA, CTAData, getCtaContent } from 'public/components/default_template/ctas';
import { useOptionalThemeColorScheme, useThemeColorScheme } from 'public/components/default_template/meta/useTheme';

import ExpandableSection from './ExpandableSection';

const filterNavCTAs = (ctas: CTAData[], useLocationNames: boolean, ctaProps?: AdditionalCTAData) =>
  ctas
    .filter(cta => cta.nav !== false)
    .map(cta => getCtaContent(cta, useLocationNames, ctaProps))
    .filter(cta => cta?.link || cta?.submenus || cta?.type === CtaType.ReservationsModal || cta?.type === 'customAction');

const ExpandableCTA = ({ cta, style }: { cta: CTAData, style?: object }) =>
  <ExpandableSection label={cta.text}>
    <>
      {cta.submenus?.map((subCTA, i) =>
        subCTA && subCTA.link ?
          <Link key={`sub-cta-${cta.text}-${i}`} href={subCTA.link} style={style}>{subCTA.text}</Link> :
          null)}
    </>
  </ExpandableSection>;

export const NavItem = ({ cta, expandable, className, color }: { cta: CTA, expandable: boolean, className?: string, color?: string | null }) => {
  const style = { ...color && { color } };
  if(cta.submenus) {
    return expandable ?
      <DropDown label={cta.text} className={className} style={style} leftAligned>
        {cta.submenus.map((cta: CTA, i: number) => {
          const link = <Link key={`cta${i}`} href={cta.link} onClick={cta.onClick}>{cta.text}</Link>;
          if(cta.utm_campaign) {
            return <AttributionContextProvider key={`cta${i}`} utm_campaign={cta.utm_campaign}>{link}</AttributionContextProvider>;
          }
          return link;
        })}
      </DropDown> :
      <ExpandableCTA cta={cta as CTAData} />;
  }
  if(cta.type === CtaType.ReservationsModal) {
    return <Button variant={ButtonType.Text} className="link" onClick={cta.onClick} style={style}>{cta.text}</Button>;
  }
  if(cta.type === 'customAction') {
    return <Button key={`${cta.text}cta`} variant={ButtonType.Text} className="link" onClick={cta.onClick} icon={cta.icon} style={style}>{cta.text}</Button>;
  }
  if(cta.link) {
    const link = <Link className={className} href={cta.link} onClick={cta.onClick} icon={cta.icon} style={style} role="button">{cta.text}</Link>;

    if(cta.utm_campaign) {
      return <AttributionContextProvider utm_campaign={cta.utm_campaign}>{link}</AttributionContextProvider>;
    }
    return link;
  }
  return null;
};

type MobileNavItemsProps = {
  ctas?: CTAData[] | null,
  ctaProps?: AdditionalCTAData,
  closeOverlay: () => void,
  color?: string | null
}
export const MobileNavItems = ({ ctas, ctaProps, closeOverlay, color }: MobileNavItemsProps) => {
  const context = useOptionalRestaurant();
  const restaurant = context?.restaurant;
  const updateLocation = context?.updateLocation;
  const getColorFromTheme = useOptionalThemeColorScheme();
  const showLocationNames: boolean = !!restaurant?.config.showLocationNames;
  const onMenuClick = useCallback((locationId?: string) => () => {
    if(locationId) {
      updateLocation && updateLocation(locationId);
    }
    closeOverlay();
  }, [updateLocation, closeOverlay]);

  const onLinkClick = useCallback((ctaOnClick?: () => void) => () => {
    if(ctaOnClick) {
      ctaOnClick();
    }
    closeOverlay();
  }, [closeOverlay]);

  const locationsWithShortUrl = useMemo(() => restaurant?.locations?.filter(loc => loc.shortUrl) || [], [restaurant]);

  if(!ctas) {
    return null;
  }
  const style = getColorFromTheme(theme => {
    return { color: theme.colorOverrides?.navigation?.text ?? theme.colorScheme.text.default };
  }, { ...color && { color } });

  return (
    <>
      {filterNavCTAs(ctas, showLocationNames, ctaProps).map((cta: CTA, i: number) => {
        if(cta.type === 'menu') {
          if(restaurant?.locations?.length === 1) {
            return <Link key="cta-menu" className="mobileLink" href={cta.link} onClick={onMenuClick()} style={style}>{cta.text}</Link>;
          } else {
            return (
              <ExpandableSection key={`cta-menu-${i}`} label={cta.text}>
                <AttributionContextProvider utm_campaign="menu">
                  {locationsWithShortUrl.map(location =>
                    <Link key={`menu-loc-${location.externalId}`} href={`/menu/${location.shortUrl}`} onClick={onMenuClick(location.externalId)} style={style}>
                      <Image className="icon" alt="Location logo" src="icons/location.svg" />{formatAddress(location)}
                    </Link>)}
                </AttributionContextProvider>
              </ExpandableSection>
            );
          }
        } else if(cta.type == 'reservationsModal') {
          return <Button key={`cta${i}`} variant={ButtonType.Text} className="mobileLink" onClick={onLinkClick(ctaProps?.reservationOnOpen)} style={style}>{cta.text}</Button>;
        } else if(cta.link) {
          return (
            <AttributionContextProvider key={`cta${i}`} utm_campaign={cta.utm_campaign}>
              <Link className="mobileLink" href={cta.link} onClick={onLinkClick(cta.onClick)} icon={cta.icon} style={style}>{cta.text}</Link>
            </AttributionContextProvider>
          );
        } else if(cta.submenus) {
          return <ExpandableCTA key={`cta${i}`} cta={cta as CTAData} style={style} />;
        } else if(cta.type === 'customAction') {
          return <Button key={`${cta.text}cta${i}`} variant={ButtonType.Text} className="mobileLink" onClick={onLinkClick(cta.onClick)} icon={cta.icon} style={style}>{cta.text}</Button>;
        } else {
          return null;
        }
      })}
    </>
  );
};

export const FixedNavItems = ({ ctas, ctaProps }: { ctas?: CTAData[] | null, ctaProps?: AdditionalCTAData }) => {
  const context = useOptionalRestaurant();

  if(!ctas) {
    return null;
  }

  let showLocationNames = false;
  if(context) {
    showLocationNames = !!context.restaurant?.config.showLocationNames;
  }

  const filteredCTAs = filterNavCTAs(ctas, showLocationNames, ctaProps);

  return (
    <>
      {filteredCTAs.map((cta: CTA, i: number) => <NavItem key={`cta${i}`} cta={cta} expandable={true} className="link" />)}
    </>
  );
};

type CTAMoreItem = { cta: CTA } & MoreItemRefs;

type ResponsiveNavItemsProps = {
  ctas?: CTAData[] | null,
  ctaProps?: AdditionalCTAData,
  parentRef: React.RefObject<HTMLDivElement>,
  moreMenuLabel?: string | null,
  color?: string | null
}
export const ResponsiveNavItems = ({ ctas, ctaProps, parentRef, moreMenuLabel, color }: ResponsiveNavItemsProps) => {
  const { restaurant } = useRestaurant();
  const getColorFromTheme = useThemeColorScheme();
  const showLocationNames: boolean = !!restaurant?.config.showLocationNames;

  const filteredCTARefs = useMemo(() =>
    filterNavCTAs(ctas || [], showLocationNames, ctaProps)
      .map(cta => ({ cta, inMoreMenu: cta?.inMoreMenu, ref: React.createRef<HTMLButtonElement>() })),
  [ctas, ctaProps, showLocationNames]);

  const textColor = useMemo(() => {
    return getColorFromTheme(theme => {
      return theme.colorOverrides?.navigation?.text ?? theme.colorScheme.text.default;
    }, color);
  }, [color, getColorFromTheme]);
  const { width } = useRefSize(parentRef);
  const {
    viewItems,
    moreItems,
    rerenderRef
  } = useMoreItems(filteredCTARefs, 100, 10, width);

  if(!ctas || !width) {
    return null;
  }

  const ctaViewItems = viewItems as CTAMoreItem[];
  const ctaMoreItems = moreItems as CTAMoreItem[];

  // the hidden items allow the useMoreItems logic to measure their width
  // for calculating which items should be shown
  return (
    <>
      <div className="responsiveNav">
        {ctaViewItems.map(({ cta }, i: number) =>
          <React.Fragment key={`view-cta${i}`}>
            {i === ctaViewItems.length - 1 && ctaMoreItems.length === 0 ? <div className="hide" ref={rerenderRef} /> : null}
            <li className="viewItem"><NavItem cta={cta} expandable={true} className="link" color={textColor} /></li>
          </React.Fragment>)}
        {ctaMoreItems.length > 0 &&
          <li className="viewItem">
            <DropDown label={moreMenuLabel || 'More'} className="link">
              {ctaMoreItems.map(({ cta }, i: number) => <NavItem key={`more-cta${i}`} cta={cta} expandable={false} />)}
            </DropDown>
          </li>}
      </div>
      <div className="hiddenNavItems">
        {filteredCTARefs.map(({ cta, ref }, i: number) =>
          cta &&
            <React.Fragment key={`hidden-more-cta${i}`}>
              <span ref={ref}><NavItem cta={cta} expandable={true} className="link" /></span>
            </React.Fragment>)}
      </div>
    </>
  );
};

export default FixedNavItems;
