import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';

import { RestaurantRoutesContextProvider } from 'src/shared/components/common/restaurant_routes/RestaurantRoutesContext';

import NoMatch404 from 'shared/components/no_match_404/NoMatch404';

import { AccountPageContent, ContextualAccountPage } from 'public/components/default_template/AccountPage';
import { ContextualCheckoutPage, CheckoutPageContent } from 'public/components/default_template/CheckoutPage';
import { ContextualConfirmationPage, ConfirmationPageContent } from 'public/components/default_template/ConfirmationPage';
import { ContextualOrderPage } from 'public/components/default_template/OrderPage';
import RestaurantProfilePage from 'public/components/default_template/RestaurantProfilePage';
import NavContent from 'public/components/default_template/nav/NavContent';
import RestaurantProfilePageWrapper, { WrappedProfilePageProps } from 'public/components/default_template/restaurant_profile/RestaurantProfilePageWrapper';
import { CustomerContextProviderWrapper } from 'public/components/online_ordering/CustomerContextWrapper';
import MapPage from 'public/components/pages/map_page/MapPage';
import Nav from 'public/components/toast_local/common/nav/Nav';
import RxSearch from 'public/components/toast_local/rx_search/RxSearch';
import Splash from 'public/components/toast_local/splash_page/Splash';
import { TOAST_LOCAL_ACCOUNT_PATH, TOAST_LOCAL_ORDER_PATH, TOAST_LOCAL_PATH } from 'public/js/siteUtilities';

import { resources } from 'config';

// Routing for www.toasttab.com/local
const ToastLocalRoutes = () => {
  const orderComponent =
    <RestaurantProfilePageWrapper>
      {({ restaurantProfileData, nearbyRxs }: WrappedProfilePageProps) =>
        <ContextualOrderPage>
          <RestaurantProfilePage restaurantProfileData={restaurantProfileData} nearbyRxs={nearbyRxs} />
        </ContextualOrderPage>}
    </RestaurantProfilePageWrapper>;

  const checkoutComponent =
    <RestaurantProfilePageWrapper>
      {() =>
        <ContextualCheckoutPage>
          <CheckoutPageContent
            nav={
              <NavContent navType="stickyNav"
                logoLink={`https://${resources.toastLocalHost}/local`}
                logoSrc="/icons/toast-logo-orange.svg"
                logoLinkSameTab
                shouldShowPreviewBanner={false} />
            } />
        </ContextualCheckoutPage>}
    </RestaurantProfilePageWrapper>;

  const confirmationComponent =
    <RestaurantProfilePageWrapper>
      {() =>
        <ContextualConfirmationPage>
          <ConfirmationPageContent
            nav={
              <NavContent navType="stickyNav"
                logoLink={`https://${resources.toastLocalHost}/local`}
                logoSrc="/icons/toast-logo-orange.svg"
                logoLinkSameTab
                shouldShowPreviewBanner={false} />
            } />
        </ContextualConfirmationPage>}
    </RestaurantProfilePageWrapper>;

  const accountComponent =
    <RestaurantProfilePageWrapper>
      {() =>
        <ContextualAccountPage>
          <AccountPageContent nav={
            <>
              <NavContent navType="stickyNav"
                logoLink={`https://${resources.toastLocalHost}/local`}
                logoSrc="/icons/toast-logo-orange.svg"
                logoLinkSameTab
                shouldShowPreviewBanner={false} />
              <ToastContainer position="bottom-center" autoClose={3000} transition={Slide} hideProgressBar newestOnTop={false} closeOnClick rtl={false} draggable pauseOnHover theme="dark" limit={2} />
            </>
          } />
        </ContextualAccountPage>}
    </RestaurantProfilePageWrapper>;

  const localAccountComponent =
    <RestaurantRoutesContextProvider homePath={TOAST_LOCAL_PATH} >
      <AccountPageContent nav={
        <Nav />
      } />
    </RestaurantRoutesContextProvider>;

  const splashComponent =
    <RestaurantRoutesContextProvider homePath={TOAST_LOCAL_PATH} accountPath={TOAST_LOCAL_ACCOUNT_PATH} >
      <Splash />
    </RestaurantRoutesContextProvider>;

  const rxSearchComponent =
    <RestaurantRoutesContextProvider homePath={TOAST_LOCAL_PATH} accountPath={TOAST_LOCAL_ACCOUNT_PATH}>
      <RxSearch />
    </RestaurantRoutesContextProvider>;

  const rxSearchComponentMapView =
    <RestaurantRoutesContextProvider homePath={TOAST_LOCAL_PATH} accountPath={TOAST_LOCAL_ACCOUNT_PATH} >
      <RxSearch mapView />
    </RestaurantRoutesContextProvider>;

  return (
    <CustomerContextProviderWrapper>
      <Switch>
        <Route exact path={`${TOAST_LOCAL_ORDER_PATH}/:slug/r-:rxGuid/checkout`} key="/checkout" component={() => checkoutComponent} />
        <Route exact path={`${TOAST_LOCAL_ORDER_PATH}/:slug/r-:rxGuid/confirm`} key="/confirm" component={() => confirmationComponent} />
        <Route exact path={`${TOAST_LOCAL_ORDER_PATH}/:slug/r-:rxGuid/account`} key="/account" component={() => accountComponent} />
        <Route exact path={`${TOAST_LOCAL_ORDER_PATH}/:slug/r-:rxGuid/:itemSlug(item-[^_]+_.+)?`} key="/menuItemModalProfile" component={() => orderComponent} />
        <Route exact path={`${TOAST_LOCAL_ORDER_PATH}/:slug/r-:rxGuid/:menuSlug([^_]+_)?:menuGuid?`} key="/menuProfile" component={() => orderComponent} />
        <Route exact path={`${TOAST_LOCAL_ORDER_PATH}/:slug/r-:rxGuid`} key="/slugProfile" component={() => orderComponent} />
        <Route exact path={`${TOAST_LOCAL_PATH}/:slug/r-:rxGuid`} key="/nonOOProfile" component={() => orderComponent} />
        <Route exact path={`${TOAST_LOCAL_PATH}/search`} key="/local/search" component={() => rxSearchComponent} />
        <Route exact path={`${TOAST_LOCAL_PATH}/search/map`} key="/local/search/map" component={() => rxSearchComponentMapView} />
        <Route exact path={TOAST_LOCAL_PATH} key="/local" component={() => splashComponent} />
        <Route exact path={TOAST_LOCAL_ACCOUNT_PATH} key={TOAST_LOCAL_ACCOUNT_PATH} component={() => localAccountComponent} />
        <Route exact path="/map" component={() => <MapPage color="#FF4C00" />} />
        <Route exact path="/sites-web/v1/map" component={() => <MapPage color="#FF4C00" />} />
        <Route component={NoMatch404} />
      </Switch>
    </CustomerContextProviderWrapper>
  );
};

export default ToastLocalRoutes;
