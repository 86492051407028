import React, { KeyboardEvent, useCallback, useState } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import useTracker from 'src/lib/js/hooks/useTracker';
import { ShowForUS } from 'src/shared/components/common/show_for_us/ShowForUS';

import { Modal, ModalOverlay, useModal } from 'shared/components/common/modal';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { PwlessAuth } from 'public/components/default_template/online_ordering/account/pwlessAuth/PwlessAuth';
import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';
import CheckoutSection from 'public/components/default_template/online_ordering/checkout/CheckoutSection';
import CustomerInfoSection from 'public/components/default_template/online_ordering/checkout/CustomerInfoSection';
import { AuthenticationSource } from 'public/components/default_template/online_ordering/checkout/checkoutUtils';
import { PackagingOptions } from 'public/components/default_template/online_ordering/checkout/packaging/PackagingOptions';
import CreditCardForm from 'public/components/default_template/online_ordering/checkout/payment/CreditCardForm';
import CreditCardFormSPI from 'public/components/default_template/online_ordering/checkout/payment/CreditCardFormSPI';
import OrderPrices from 'public/components/default_template/online_ordering/checkout/payment/OrderPrices';
import SavedCreditCards, { AcceptedPaymentMethods } from 'public/components/default_template/online_ordering/checkout/payment/SavedCreditCards';
import TipSelector from 'public/components/default_template/online_ordering/checkout/payment/TipSelector';
import { useCart } from 'public/components/online_ordering/CartContext';
import { useCheckout } from 'public/components/online_ordering/CheckoutContext';
import { Customer, useCustomer } from 'public/components/online_ordering/CustomerContextCommon';
import { PaymentOption, usePayment } from 'public/components/online_ordering/PaymentContext';

import DiscountsSection from './Discounts/DiscountsSection';
import Fundraising from './Fundraising';
import PaymentOptionsSection from './PaymentOptionsSection';
import { useSpi } from './useSpi';

interface PaymentSectionProps {
  isExpanded: boolean;
}

const PaymentSection = ({ isExpanded }: PaymentSectionProps) => {
  const { ooRestaurant } = useRestaurant();
  const { cart } = useCart();
  const { customer } = useCustomer();
  const { paymentOption, fundraisingEnabled, tipEnabled, setTipAmount } = usePayment();
  const { setPackagingOptions, packagingOptions } = useCheckout();
  const [selectedTipButton, setSelectedTipButton] = useState('');
  const { isOpen, onClose, onOpen } = useModal();
  const tracker = useTracker();
  const { enabledPaymentOptions } = useCheckout();

  const clickLogIn = useCallback(() => {
    onOpen();
    tracker.track('Started Authentication', { source: AuthenticationSource.GuestCheckout } );
  }, [onOpen, tracker]);

  const keyDownLogIn = useCallback((e: KeyboardEvent) => {
    switch(e.key) {
      case ' ':
      case 'Enter':
        e.preventDefault();
        e.stopPropagation();
        clickLogIn();
        break;
    }
  }, [clickLogIn]);

  const onTipChange = useCallback((buttonId: string, tip: number) => {
    setSelectedTipButton(buttonId);
    setTipAmount(tip);
  }, [setTipAmount, setSelectedTipButton]);

  if(!ooRestaurant || !cart) {
    return null;
  }

  const canPayAtCheckout = !enabledPaymentOptions.has(PaymentOption.UponReceipt) || enabledPaymentOptions.size > 1;

  return (
    <>
      {customer ?
        <CustomerInfoSection /> :
        <ShowForUS>
          <CheckoutSection title="Checkout as a guest">
            <div id="alreadyhaveacheckoutcta">
              <span>Already have a Toast account? For faster checkout,{' '}</span>
              <span aria-labelledby="alreadyhaveacheckoutcta" tabIndex={0} role="button" className="logInLink" onKeyDown={keyDownLogIn} onClick={clickLogIn}>log in.</span>
            </div>
            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay fadeIn fadeOut />
              <PwlessAuth checkingOut source={AuthenticationSource.GuestCheckout} />
            </Modal>
          </CheckoutSection>
        </ShowForUS>}

      <CheckoutSection title="Payment">
        {canPayAtCheckout && <AcceptedPaymentMethods />}
        <PaymentOptionsSection />
        <ShowForUS>
          <CreditCardInfo paymentOption={paymentOption} customer={customer} />
        </ShowForUS>
      </CheckoutSection>

      {!customer && <CustomerInfoSection />}
      <CheckoutSection title={'Discounts'}>
        {
          isExpanded && <DiscountsSection />
        }
      </CheckoutSection>
      <AnimatedSection expanded={tipEnabled}>
        <CheckoutSection>
          <TipSelector tipOptions={cart.preComputedTips} selectedTipButton={selectedTipButton} onChange={onTipChange} />
        </CheckoutSection>
      </AnimatedSection>
      {
        ooRestaurant?.packagingConfig?.enabled &&
          <CheckoutSection title="Packaging options">
            <PackagingOptions packagingConfig={ooRestaurant.packagingConfig} selectedPackagingOptions={packagingOptions} onPackagingOptionsUpdate={setPackagingOptions} />
          </CheckoutSection>
      }
      {fundraisingEnabled &&
        <CheckoutSection>
          <FundraisingSection />
        </CheckoutSection>}
      <OrderPrices canShowSurcharges={isExpanded} />
    </>
  );
};

const CreditCardInfo = ({ paymentOption, customer }: {paymentOption: PaymentOption | null, customer?: Customer}) => {
  const { spiEnabled } = useSpi();
  const { ooToastCashSpend: ooToastCashSpendEnabled } = useFlags();
  const { orderTotal } = useCheckout();

  if(spiEnabled) {
    return (
      <AnimatedSection expanded={paymentOption === PaymentOption.PayNow}>
        <CreditCardFormSPI allowToastCash={ooToastCashSpendEnabled} disableSPI={orderTotal <= 0} />
      </AnimatedSection>
    );
  }

  return (
    <>
      <AnimatedSection expanded={paymentOption === PaymentOption.CreditCard && (customer?.creditCards?.length || 0) > 0}>
        <SavedCreditCards />
      </AnimatedSection>
      <AnimatedSection expanded={paymentOption === PaymentOption.CreditCard && (customer?.creditCards?.length || 0) === 0}>
        <CreditCardForm showCheckbox={Boolean(customer)} />
      </AnimatedSection>
    </>
  );
};

const FundraisingSection = () => {
  const { ooRestaurant } = useRestaurant();
  const { cart } = useCart();
  const { tipAmount, fundraisingEnabled, setFundraisingAmount, fundraisingAmount } = usePayment();
  const fundraisingCampaign = ooRestaurant?.fundraisingConfig;
  return (
    <Fundraising
      fundraisingCampaign={fundraisingCampaign}
      onChange={(amount: number) => setFundraisingAmount(amount)}
      orderTotal={(cart?.order?.totalV2 || 0) + tipAmount}
      fundraisingEnabled={fundraisingEnabled}
      fundraisingAmount={fundraisingAmount} />
  );
};

export default PaymentSection;
