import React, { ReactNode } from 'react';
import { withRouter } from 'react-router-dom';

import { useEditor } from '@toasttab/sites-components';

import { BannerPlacement } from 'src/apollo/sites';
import { RequestContextProps } from 'src/lib/js/context';
import { isToastOrderRequest } from 'src/public/js/siteUtilities';

import Image from 'shared/components/common/Image';
import Link from 'shared/components/common/link';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import SpotlightBanner from 'public/components/default_template/spotlight/SpotlightBanner';

import { Locations } from './location/Locations';

type FooterProps = { children?: ReactNode };

type WrappedFooterProps = FooterProps & Pick<RequestContextProps, 'staticContext'>

const WrappedFooter = ({ children, staticContext }: WrappedFooterProps) => {
  const { restaurant } = useRestaurant();
  const { useEditableRef } = useEditor();
  const { editableRef } = useEditableRef<HTMLDivElement>({ name: 'footer', displayName: 'Footer', actions: [], schema: { fields: [] } });

  if(!restaurant) {
    return null;
  }

  return (
    <>
      <SpotlightBanner placement={BannerPlacement.Bottom} fixed="both" />
      <div role="region" tabIndex={0} className="templateFooter" id="footer" ref={editableRef}>
        <Locations />
        <div className="legal-container" role="contentinfo">
          <div className="poweredBy">
            This site is powered by
            <Link href="https://toasttab.com/local" blockAttribution aria-label="Toast logo">
              <Image aria-hidden="true" alt="Toast logo" src="icons/toast-logo-filled.svg" />
            </Link>
          </div>
          <div className="legal">
            <a href="https://pos.toasttab.com/terms-of-service/#diner-tos" rel="noopener noreferrer" target="_blank">
              Terms of Service
            </a>{' '}
            |{' '}
            <a href="https://pos.toasttab.com/privacy" rel="noopener noreferrer" target="_blank">
              Privacy Statement
            </a>
            {isToastOrderRequest(staticContext) &&
            <p>
              <span>|</span>
              <a href="https://pos.toasttab.com/privacy#addendum-a" rel="noopener noreferrer" target="_blank">CA Privacy Statement</a>
            </p>}
          </div>
        </div>
        {children}
      </div>
    </>
  );
};

const FooterWithRouter = withRouter<FooterProps & RequestContextProps, React.ComponentType<FooterProps & RequestContextProps>>(WrappedFooter);

const Footer = (props: FooterProps) => {
  const { isEditor } = useEditor();

  if(isEditor) {
    return <WrappedFooter {...props } />;
  }

  return <FooterWithRouter {...props} />;
};

export default Footer;
