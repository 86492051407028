import React from 'react';

import { GuestWalletQuery, useGuestWalletLazyQuery, useGuestWalletQuery } from 'src/apollo/onlineOrdering';
import { useCheckout } from 'src/public/components/online_ordering/CheckoutContext';
import { useCustomer } from 'src/public/components/online_ordering/CustomerContextCommon';
import { PaymentOption, usePayment } from 'src/public/components/online_ordering/PaymentContext';
import Image from 'src/shared/components/common/Image';
import { ToggleInput } from 'src/shared/components/common/forms';

const ToastCashToggleCheckbox = (props: {
  shouldUseToastCash: boolean
  setShouldUseToastCash: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  return (
    <div
      className="toastCashPaymentToggle">
      <ToggleInput
        id={'toast-cash-checkbox'}
        name={'should-use-toast-cash'}
        onChange={e => {
          props.setShouldUseToastCash(e.target.checked);
        }}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          if(event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            props.setShouldUseToastCash(v => !v);
          }
        }}
        type="checkbox"
        ariaLabel={'Toast Cash'}
        checked={props.shouldUseToastCash}>
        <div
          className="toastCashIcon">
          <Image alt="Toast Cash" src="icons/toast-cash-icon.svg" />
          <div>
          Toast Cash
          </div>
        </div>
      </ToggleInput>
    </div>
  );
};

function getGuestAvailableBalance(data: GuestWalletQuery): number {
  return Number(data.guest.wallet.guestCurrencyAccount.availableBalance.amount);
}

/**
 * Handles fetching of Toast Cash balance + synchronization with the payment context state
 * Includes opinionated styling for usage in a SPI context
 */
export const ToastCashPaymentToggle = (props: { wrapperStyle?: React.StyleHTMLAttributes<HTMLDivElement> }) => {
  const { setToastCashInfo, paymentOption } = usePayment();
  const { orderTotal } = useCheckout();
  const hasCustomer = Boolean(useCustomer().customer);
  const { data } = useGuestWalletQuery({ skip: !hasCustomer, variables: { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone } });
  const availableBalance = data ? getGuestAvailableBalance(data) : 0.0;
  const currencyAccountId = data?.guest.wallet.guestCurrencyAccount.id;
  const isValidPaymentContext = paymentOption == PaymentOption.PayNow && orderTotal >= 0;
  const [checked, setChecked] = React.useState(isValidPaymentContext);
  React.useEffect(() => {
    if(!currencyAccountId || typeof availableBalance !== 'number' || !hasCustomer) {
      return;
    }
    // If payment context validity changes, then clear out
    if(!isValidPaymentContext) {
      setChecked(false);
      setToastCashInfo(null);
    } else {
      setChecked(true);
      setToastCashInfo({ toastCashAccountId: currencyAccountId, toastCashAvailableAmount: availableBalance });
    }
  }, [availableBalance, currencyAccountId, isValidPaymentContext, setToastCashInfo, hasCustomer]);


  if(!availableBalance || !hasCustomer || !currencyAccountId || !isValidPaymentContext) {
    return null;
  }

  return (
    <div className="toastCashPaymentToggleWrapper"
      style={props.wrapperStyle}>
      <ToastCashToggleCheckbox
        setShouldUseToastCash={checked => {
          setChecked(checked);
          if(checked) {
            setToastCashInfo({ toastCashAccountId: currencyAccountId, toastCashAvailableAmount: availableBalance });
          } else {
            setToastCashInfo(null);
          }
        }}
        shouldUseToastCash={checked} />
    </div>);
};

const noop = () => {};
/**
 * Allows to re-hydrate the state of the guest wallet. Guards in cases where a guest account is not present.
 */
export const useRefreshToastCashAmount = () => {
  const hasCustomer = Boolean(useCustomer().customer);
  const [_, { refetch }] = useGuestWalletLazyQuery();
  const refetchCallback = React.useCallback(() => {
    refetch().catch(() => {
      // swallow errors
    });
  }, [refetch]);
  if(!hasCustomer) {
    return noop;
  }
  return refetchCallback;
};
