import React, { MutableRefObject, useMemo, useRef } from 'react';

import classnames from 'classnames';

import Image from 'shared/components/common/Image';

import { formatRXName } from 'public/components/default_template/restaurant_profile/formatting';

import { resources } from 'config';

import CarouselListing from './CarouselListing';

/** Scrolls a div with the ref `scrollableElementRef` by the amount
 * of pixels in the `xDirection` and `yDirection` */
const scrollDiv = (scrollableElementRef: MutableRefObject<HTMLDivElement | null>, xDirection = 0, yDirection = 0) => {
  const refEl = scrollableElementRef.current;
  if(refEl) {
    // scroll to the start or end if it is closer than the suggested scroll amount.
    if(xDirection < 0) {
      xDirection = Math.max(xDirection, -refEl.scrollLeft);
    } else {
      xDirection = Math.min(xDirection, refEl.scrollWidth - refEl.scrollLeft);
    }
    if(yDirection < 0) {
      yDirection = Math.max(yDirection, -refEl.scrollTop);
    } else {
      yDirection = Math.min(yDirection, refEl.scrollHeight - refEl.scrollTop);
    }
    refEl.scrollBy({ left: xDirection, top: yDirection, behavior: 'smooth' });
  }
};

const RXCarousel = ({ title, elements, elementClass, className, moreRxHref, moreRxDescription }: Props) => {
  // Each scroll will move the view window by one carousel object (304px wide).
  const SCROLL_AMOUNT = 304;

  const restaurantsRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const carouselListings = useMemo(() => {
    return elements.filter(el => el.consumerAppDisplayName).map(el => {
      return (
        <CarouselListing
          title={formatRXName(el.consumerAppDisplayName!)}
          linkToURL={`https://${resources.toastLocalHost}/local/order${el.shortUrl ? `/${el.shortUrl}` : ''}/r-${el.guid}`}
          className={classnames(elementClass || 'pure-u-24-24 pure-u-md-12-24')}
          key={el.consumerAppDisplayName}
          imgUrl={el.consumerAppListImage} />
      );
    });
  }, [elements, elementClass]);

  return (
    <div className={classnames('rx-carousel', className)}>
      <div className="title-section">
        <h2>{formatRXName(title)}</h2>
        <div className="scroll-button-section hidden-sm-down">
          <button className="arrow" onClick={() => scrollDiv(restaurantsRef, -SCROLL_AMOUNT)}><Image src="/icons/chevron-left-gray.svg" alt="scroll left"></Image></button>
          <button className="arrow" onClick={() => scrollDiv(restaurantsRef, SCROLL_AMOUNT)}><Image src="/icons/chevron-right-gray-24.svg" alt="scroll right"></Image></button>
        </div>
      </div>
      <div className="element-grid">
        <div className="pure-g restaurants" ref={restaurantsRef}>{carouselListings}</div>
      </div>
      <div className="more-rx-button hidden-sm-down">
        {moreRxHref && moreRxDescription && <a href={moreRxHref} target="_blank" rel="noreferrer" className="btn"><div className="btn-text">{moreRxDescription}</div></a>}
      </div>
    </div>
  );
};


type Props = {
  title: string,
  elements: CarouselElement[],
  elementClass?: string,
  className?: string,
  moreRxHref?: string,
  moreRxDescription?: string
}

export interface CarouselElement {
  guid: string,
  consumerAppDisplayName?: string | null,
  shortUrl?: string | null,
  consumerAppListImage?: string | null
}

export default RXCarousel;
