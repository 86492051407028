import * as Sentry from '@sentry/react';
import parseISO from 'date-fns/parseISO';

import {
  MenuApiType,
  // eslint-disable-next-line camelcase
  Menus_VisibilityEnum,
  useMenusQuery,
  usePopularItemsQuery,
  RestaurantQuery,
  MenusQuery,
  MenusInput,
  DateTimeRange
} from 'src/apollo/onlineOrdering';
import { PopularItemsQuery } from 'src/apollo/onlineOrdering';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import { MenuItem } from 'public/components/default_template/menu_section/MenuSection';
import { getItemsFlat } from 'public/components/default_template/online_ordering/upsell/utils/upsellUtils';

import { useOptionalFulfillment } from './FulfillmentContext';

export type Restaurant = RestaurantQuery['restaurantV2'] & { __typename: 'Restaurant' };
export type MenusResponse = MenusQuery['menusV3'] & { __typename: 'MenusResponse' };
export type Menus = MenusResponse['menus'];
export type MenuGroups = Menus[0]['groups'] & { __typename: 'MenuGroup' };

type Props = {
  respectAvailability?: boolean;
  channelGuid?: string | null;
  skipPopularItems?: boolean;
  hideOutOfStockItems?: boolean;
  skip?: boolean;
};

export const useOOMenus = ({ respectAvailability = true, channelGuid, skipPopularItems = false, hideOutOfStockItems = false, skip = false }: Props) => {
  const fulfillmentContext = useOptionalFulfillment();
  const fulfillmentData = fulfillmentContext?.fulfillmentData;
  const { selectedLocation, restaurant, ooRestaurant } = useRestaurant();
  const shortUrl = selectedLocation.shortUrl;
  const restaurantGuid = selectedLocation.externalId;
  const ooOnly = restaurant.config.isOnlineOrderingOnly ?? true;
  const hasOOModule = ooRestaurant?.hasOnlineOrderingModule ?? true;
  const shouldSkip = skip || ooOnly && !hasOOModule;

  const menusInput: MenusInput = {
    shortUrl: shortUrl || '',
    restaurantGuid,
    menuApi: MenuApiType.Do,
    // eslint-disable-next-line camelcase
    visibility: Menus_VisibilityEnum.ToastOnlineOrdering,
    dateTime: fulfillmentData?.cartFulfillmentData.fulfillmentDateTime,
    respectAvailability,
    hideOutOfStockItems
  };

  if(channelGuid) {
    menusInput.channelGuid = channelGuid;
  }

  const {
    data: menuData,
    error: menuErr,
    loading: menuLoading
  } = useMenusQuery({
    variables: { input: menusInput },
    skip: !restaurantGuid || !shortUrl || shouldSkip,
    fetchPolicy: 'cache-and-network'
  });

  const {
    data: popularItemsData,
    error: popularItemsErr,
    loading: popularItemsLoading
  } = usePopularItemsQuery({
    variables: {
      input: {
        menusInput: menusInput,
        restaurantGuid: restaurantGuid
      }
    },
    skip: !restaurantGuid || !shortUrl || skipPopularItems || shouldSkip,
    fetchPolicy: 'cache-and-network'
  });

  if(shouldSkip) {
    return { menus: [], popularItems: [] };
  }

  if(menuLoading && !menuData || popularItemsLoading && !popularItemsData) {
    return { loading: true };
  }

  if(!skipPopularItems && popularItemsErr) {
    Sentry.captureMessage(`Failed to load Popular Items: ${popularItemsErr}`, { level: 'info', extra: { menusInput } });
  }

  if(menuErr) {
    Sentry.captureMessage(`Failed to load Menu: ${menuErr}`, { level: 'info', extra: { menusInput } });
    return { error: menuErr };
  }

  const menuWrapper: MenusResponse | undefined = menuData?.menusV3 as MenusResponse | undefined;
  const menus = menuWrapper?.menus
    ?.filter(menu => menu.groups?.length
      && menu.groups.some(group => group?.items?.length));

  const shouldPreorderItemBeVisible = (item: MenuItem | null) => {
    const preorderRule = item?.timeBasedRules?.preorderRule;
    if(!preorderRule) {
      return true;
    }
    const { start, end } = preorderRule.orderDateRange as DateTimeRange;
    const now = new Date(Date());
    return parseISO(start) <= now && (!end || parseISO(end) >= now);
  };

  // **TEMPORARY**
  // Filter out any items with preorder rules whose orderDateRanges are in the past or the future
  let mappedMenus: Menus | undefined = menus?.map(menu => ({
    ...menu,
    groups: menu.groups?.map(group => {
      if(!group?.items) {
        return group;
      }
      return {
        ...group,
        items: group.items.filter(item => shouldPreorderItemBeVisible(item))
      };
    })
  }));

  if(mappedMenus?.every(menu => menu.groups?.length === 1)) {
    const menuGroups: MenuGroups = mappedMenus
      .flatMap(menu => menu.groups)
      .filter(Boolean) as MenuGroups;

    mappedMenus = [{
      name: 'Menu',
      guid: 'Menu',
      groups: menuGroups
    }];
  }

  if(skipPopularItems) return { menus: mappedMenus, popularItems: [] };

  const popularItemsQuery: PopularItemsQuery | undefined = popularItemsData;
  let popularItems: Array<MenuItem> = [];
  if(popularItemsQuery?.popularItems && 'items' in popularItemsQuery.popularItems) {
    popularItems = popularItemsQuery.popularItems.items;
  }

  if(selectedLocation.featuredItems?.enabled && !selectedLocation.featuredItems?.useDefaults) {
    const menuItemMasterIds = new Set(selectedLocation.featuredItems.menuItemMasterIds);
    const items = getItemsFlat(mappedMenus);

    popularItems = [...items.filter(item => menuItemMasterIds.has(item.masterId)), ...popularItems.filter(item => !menuItemMasterIds.has(item.masterId || ''))].slice(0, 3);
  }

  // **TEMPORARY**
  // Filter out any popular items with preorder rules whose orderDateRanges are in the past or the future
  popularItems = popularItems.filter(item => shouldPreorderItemBeVisible(item));

  return { menus: mappedMenus, popularItems };
};
